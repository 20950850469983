import React, { createContext, useContext, useState,useEffect } from "react";
import jwt_decode from "jwt-decode";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {

  const [authorizationToken, setAuthorizationToken] = useState(null);
  const [user,setUser] = useState(null); //keep username
  const [completeLoading, setCompleteLoading] = useState(false);
  const [userData,setUserData] = useState(null);  //user data like id, name, phone num ..etc

  const saveAuthorizationToken = (token)=>{

    localStorage.setItem("authorizationToken", token);
    setAuthorizationToken(token);
    setTokenDetails(token);

  }

  const setTokenDetails = ((token)=>{

    //decode token
    try {
        // Decode the JWT token
        const decodedToken = jwt_decode(token);
        //console.log("token: "+JSON.stringify(decodedToken));
        //console.log("sub: "+decodedToken.sub);
        //console.log("roles: "+decodedToken.roles);
        //console.log("exp: "+decodedToken.exp);
        // token: {"sub":"soonyee80@gmail.com","roles":"[USER]","exp":1693661850}

        const user={
            username: decodedToken.sub,
            roles: decodedToken.roles,
            expiry_date: decodedToken.exp
        }

        setUser(user);

      } catch (error) {
        //console.error("Error decoding JWT token:", error);
      }
});



const getUserFromLocalstorage = ()=>{

    // Retrieving the token
    const token = localStorage.getItem("authorizationToken");

    //console.log("jwtToken: "+token);
    if(token!==null){
        setAuthorizationToken(token);
        setTokenDetails(token);

        const decodedToken = jwt_decode(token);

        const user={
            username: decodedToken.sub,
            roles: decodedToken.roles,
            expiry_date: decodedToken.exp
        }

        const timestampInSecondsCurrentTime = Math.floor(new Date().getTime() / 1000);
        const expiry_date = decodedToken.exp; //time in seconds

        //check jwt token expiry
        if(timestampInSecondsCurrentTime > expiry_date){
          removeUser();
          return null;
        }else{
          return user;
        }

    }else return null;

}



useEffect(() => {

    const userData = getUserFromLocalstorage();
    //console.log("userData: "+userData)
    setUser(userData);
    //console.log("--completeloading-- ")
    setCompleteLoading(true);

}, []); // Empty dependency array to run once on mount



//logout user
const removeUser=()=>{
    setAuthorizationToken(null);
    setUser(null);
    localStorage.clear();
}


return (
<UserContext.Provider value={{ authorizationToken, saveAuthorizationToken ,user, removeUser, completeLoading, userData,setUserData }}>
    {children}
</UserContext.Provider>
);

}

