import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useAlertContext} from "../../context/AlertContext";
import {useEffect} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import {
    Flex,
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormErrorMessage,
    Text,
    Divider,
    Container
  } from "@chakra-ui/react";



function CreateNewAccountVerifyOtp(props){

    const { onOpen } = useAlertContext();
    const navigate = useNavigate();

    const TITLE_SUCCESSFUL_CREATE_ACCOUNT_VERIFY_OTP="Successful";
    const TITLE_ERROR_CREATE_ACCOUNT_VERIFY_OTP="Error when creating your account";
    const DESCRIPTION_SUCCESSFUL_CREATE_ACCOUNT_VERIFY_OTP="Account created successfully.";


    const CREATE_ACCOUNT_VERIFY_OTP_URL= BASE_URL+ "/api/v1/user/create_account_verify_otp";

    const [handleCreateAccountVerifyOtpApiCall,isLoadingCreateAccountVerifyOtp, dataCreateAccountVerifyOtp, errorCreateAccountVerifyOtp] = useApiCalls();

    const digitsOnly = (value) => /^\d+$/.test(value);

    const formik = useFormik({
        initialValues: {
          verificationCode:""
        },

        onSubmit: (values)=>{
            submitCreateAccountVerifyOtp(values);
        },

    validationSchema: Yup.object({
        verificationCode: Yup.string()
            .test("Digits only", "Must be 6 digit number", digitsOnly)
            .min(6,"Must be 6 digit.")
            .max(6, "Must be 6 digit")
            .required("Required")
      })
    });

    
  useEffect(()=>{

    if(dataCreateAccountVerifyOtp!==null){
        if(dataCreateAccountVerifyOtp.content.status==="successful"){
            onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_CREATE_ACCOUNT_VERIFY_OTP, DESCRIPTION_SUCCESSFUL_CREATE_ACCOUNT_VERIFY_OTP);
            navigate("/login");
        }
    }

  },[dataCreateAccountVerifyOtp])


  useEffect(()=>{

        if(errorCreateAccountVerifyOtp!==null){
            onOpen(STATUS_ERROR, TITLE_ERROR_CREATE_ACCOUNT_VERIFY_OTP, errorCreateAccountVerifyOtp);
        }

  },[errorCreateAccountVerifyOtp])



  function submitCreateAccountVerifyOtp(values){

    //headers
    const config = {
        headers:{
            "Content-Type": "application/json"
        },
    params:{}
    };

    //body json params
    const apiJsonParams = {
        phone_number: props.newUser.phone_number,
        verification_uuid: props.newUser.verification_uuid,
        password: props.newUser.password,
        verification_code: values.verificationCode
    };

    const apiCalls ={
        apiConfig: config,
        url: CREATE_ACCOUNT_VERIFY_OTP_URL,
        apiType: "post",
        apiJsonParams: apiJsonParams
    }

    handleCreateAccountVerifyOtpApiCall({apiCalls});
}

    return(

      <Container maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }} borderWidth={0} borderColor={"blue"} align="top" minHeight="70vh" justifyContent="center">

      <Flex direction="column" justifyContent="center" >

      <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth="400px" mb={2} borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg"> 

        <Box pt={2} textAlign="center">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Verify OTP</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit}>

        <FormControl p={2} isInvalid={(formik.touched.verificationCode && formik.errors.verificationCode)} >
        <FormLabel>Verification Code (OTP)</FormLabel>
        <Input 
            type="text" 
            placeholder=""
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...formik.getFieldProps("verificationCode")}
        />
        <FormErrorMessage>{formik.errors.verificationCode}</FormErrorMessage>
        <Divider/>
        <Text color="blue">Check your SMS for the OTP.</Text>
        </FormControl>

        <Button width="full" mt={4} type="submit" isLoading={isLoadingCreateAccountVerifyOtp}>
        Verify
        </Button>

        </form>

    </Box>

    </Container>
    </Flex>
    </Container>

    );

}
export default CreateNewAccountVerifyOtp;