import FooterLogo from "../../assets/ereloadsgLogo.jpg";
import {
    Image,
    Flex,
    Text,
    Box
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {useUser} from "../../context/UserContext";

function Footer(){

    const {user} = useUser();


    return(



        <Flex p={5}  bg="#00008B" w="100vw" minHeight="20vh"  color="#FFFFFF" gap={{ base: "50px",md: "100px"}}  flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>

            <Box><Link to="/" ><Image src={FooterLogo}  w={{ base: "76px",sm: "76px", md: "113px"}} h={{ base: "54px", sm: "54px", md: "80px"}}   borderRadius={8} m={2} alt="eReloadSg Logo" /></Link></Box>

            <Flex direction={"column"}>
            <Link to="/reload_home" ><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Buy Now</Text></Link>
            {user===null && <Link to="/create_new_account_main" ><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Register Account</Text></Link>}
            {user===null && <Link to="/forget_password" ><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Forget Password</Text></Link>}
            {user!==null && <Link to="/profile" ><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>My Profile</Text></Link>}

            {user!==null && <Link to="/search_wallet_transaction"><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Wallet Transactions</Text></Link>}
            {user!==null && <Link to="/search_reload"><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Reload Transactions</Text></Link>}
            {user!==null && <Link to="/wallet_home"><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Wallet Recharge</Text></Link>}
            {user!==null && <Link to="/change_password" ><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Change Password</Text></Link>}
            <Link to="/contact_us" ><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Contact Us</Text></Link>
            <Link to="/privacy" ><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Privacy</Text></Link>
            <Link to="/terms_and_conditions" ><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Terms and Conditions</Text></Link>
            {user===null && <Link to="/login"><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Login</Text></Link>}
            {user!==null && <Link to="/logout"><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Logout</Text></Link>}
            </Flex>

            <Box >
            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Copyright Easy Reload System Pte Ltd</Text>
            </Box>

        </Flex>


    );
}
export default Footer;