import {useEffect} from "react";
import {useUser} from "../../context/UserContext";
import LoginForm from "./LoginForm";
import {
    Flex,
    Container
  } from "@chakra-ui/react";
  import {useAlertContext} from "../../context/AlertContext";
  import {useApiCalls} from "../../hooks/useApiCalls";
  import {useNavigate} from "react-router-dom";
  import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";

function Login(){

    const navigate = useNavigate();
    const {saveAuthorizationToken} = useUser();
    const {onOpen} = useAlertContext();

    const TITLE_ERROR_USER_LOGIN="Error when login to your account";
    const TITLE_SUCCESSFUL_USER_LOGIN="Login successful";
    const DESCRIPTION_SUCCESSFUL_USER_LOGIN="Your have logon to your account.";

    const POST_USER_LOGIN_URL= BASE_URL + "/api/v1/user/login";

    const [handleUserLoginApiCall,isLoadingUserLogin, dataUserLogin, errorUserLogin] = useApiCalls();


    useEffect(()=>{

        if(dataUserLogin!==null){
            if(dataUserLogin.content.status==="successful"){
                onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_USER_LOGIN, DESCRIPTION_SUCCESSFUL_USER_LOGIN);
                const authorizationToken = dataUserLogin.content.authorization;
                saveAuthorizationToken(authorizationToken);
                navigate("/reload_home");
            }
        }

    },[dataUserLogin])


    useEffect(()=>{
        if(errorUserLogin!==null){
            onOpen(STATUS_ERROR, TITLE_ERROR_USER_LOGIN, errorUserLogin);
        }
    },[errorUserLogin])


    function submitLoginForm(values){

        //headers
        const config = {

            params:{
                username: values.userName,
                password: values.password,
                }
        };

        //body json params
        const apiJsonParams = {};

        const apiCalls ={
            apiConfig: config,
            url: POST_USER_LOGIN_URL,
            apiType: "post",
            apiJsonParams: apiJsonParams
        }

        handleUserLoginApiCall({apiCalls});//call api
    }

  

    return(

    <Container maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }} borderWidth={0} borderColor={"blue"} align="top" minHeight="70vh" justifyContent="center" >


        <Flex direction="column" justifyContent="center" >

        <LoginForm submitLoginForm={submitLoginForm} isLoading={isLoadingUserLogin}/>

        </Flex>



    </Container>

    );
}
export default Login;
