import {BASE_URL} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useUser} from "../../context/UserContext";
import {useEffect} from "react";

function Logout(){

    const {removeUser,user,authorizationToken} = useUser();

    const POST_LOGOUT_URL = BASE_URL + "/api/v1/user/logout";

    const [handleLogoutUserApiCall,isLoadingLogoutUser, dataLogoutUser, errorLogoutUser] = useApiCalls();


    useEffect(()=>{

        if(user!==null){
            //console.log("perform remove user")
            removeUser();
            sendLogout();
        }

    },[]);


//send logout api request to backend to logout the jwt token
//basically frontend will not need to handle the response from it, as it has
//deleted the token and clear the local storage.
    function sendLogout(){

         //headers
        const config = {
            headers:{
                Authorization: `Bearer ${authorizationToken}`
            }
          };

        const apiJsonParams = {};

        const apiCalls ={
          apiConfig: config,
          url: POST_LOGOUT_URL,
          apiType: "post",
          apiJsonParams: apiJsonParams
        }

      handleLogoutUserApiCall({apiCalls});

    }

}
export default Logout;