import {
    Text,
    Box,
    Heading,
    Container,
    Icon
} from '@chakra-ui/react';
import { FaWhatsapp } from "react-icons/fa";

function ContactUs(){


    return(

        <Container  borderWidth={0} borderColor={"blue"}  maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }} >

        <Box textAlign="center" borderWidth={0} borderRadius={8} boxShadow="lg">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Contact Us</Heading>
        </Box>

        <Box pt={10} textAlign={"center"}>

        <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }} display={"inline"}>WhatsApp </Text>
        <Icon as={FaWhatsapp} w={10} h={10} color="#25D366" />
        <Text as={"i"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }} color={"blue"}> 94564710</Text>
        <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }}>If you encountered issue when using the reload service. Thank you.</Text>

        </Box>

        <Box  pt={10} textAlign={"center"}>
        <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }}>Address</Text>
        <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }}>71 Pioneer Road</Text>
        <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }}>#01-K2</Text>
        <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }}>Singapore 639591</Text>
        </Box>

        </Container>


    );


}
export default ContactUs;