import HeaderLogo from "../../assets/ereloadsgLogo.jpg";
import {HamburgerIcon} from "@chakra-ui/icons"
import {Link} from "react-router-dom";
import {useUser} from "../../context/UserContext";
import useWallet from "../../hooks/useWallet";
import ButtonLink from "../../components/ButtonLink";
import { VscAccount } from "react-icons/vsc";
import { Icon} from "@chakra-ui/icons";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    HStack,
    VStack,
    Stack,
    Image,
    Center,
    IconButton,
    Text,
    Divider,
    Flex
  } from "@chakra-ui/react"
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
  } from "@chakra-ui/react"


//base = 0em, sm = 30em, md = 48em, lg = 62em, xl = 80em,, 2xl = 96em
/*const breakpoints = {
    base: "0em", // 0px
    sm: "30em", // ~480px. em is a relative unit and is dependant on the font size.
    md: "48em", // ~768px
    lg: "62em", // ~992px
    xl: "80em", // ~1280px
    "2xl": "96em", // ~1536px
  };
  e.g w={{ base: "75px", md: "113px"}} h={{ base: "60px", md: "80px"}}
  */
  
  /*  w={{ base: "px",sm: "px", md: "px"}}
      fontSize={{ base: "0.5rem", sm: "1rem", md: "1.5rem" }}
*/

  /*function getScreenSize(){

    let w =window.innerWidth;
    let h = window.innerHeight;

    return "width:"+w+","+"height:"+h;

  }*/

function Header(){

    const {user} = useUser();
    const {wallet} = useWallet();

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (

            <HStack bg="#00008B" w="100vw"  color="#FFFFFF">

            <Drawer onClose={onClose} isOpen={isOpen} placement="left" >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton color="#ffffff"/>
            <DrawerBody background="#00008B">
            <VStack>
                {user!==null && <Icon as={VscAccount} w="6" h="6"  color='#FFFFFF' background= "#00008B"/>}
                {user!==null && <Text lineHeight={1} color='#FFFFFF'>{`${user.username}`}</Text>}
                {(user!==null && wallet!==null && (wallet?.balance>0)) && <Text lineHeight={2} color='#FFFFFF'>{`Wallet: $${wallet.balance}`}</Text>}
                <Divider/>
                <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/reload_home" link_text="Buy Now" onClick={onClose}/>
                {user===null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/create_new_account_main" link_text="Register Account" onClick={onClose}/>}
                {user===null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/forget_password" link_text="Forget Password" onClick={onClose}/>}
                {user!==null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/profile" link_text="My Profile" onClick={onClose}/>}
                {user!==null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/change_password" link_text="Change Password" onClick={onClose}/>}
                {user!==null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/search_wallet_transaction" link_text="Wallet Transactions" onClick={onClose}/>}
                {user!==null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/search_reload" link_text="Reload Transactions" onClick={onClose}/>}
                {user!==null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/wallet_home" link_text="Wallet Recharge" onClick={onClose}/>}
                <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/contact_us" link_text="Contact Us" onClick={onClose}/>
                <Divider/>
                {user===null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/login" link_text="Login" onClick={onClose}/>}
                {user!==null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/logout" link_text="Logout" onClick={onClose}/>}
            </VStack>
            </DrawerBody>
            </DrawerContent>
            </Drawer>

            <Center w="20vw" >
            <Link to="/" ><Image m={2} src={HeaderLogo}  ml={1} w={{ base: "76px",sm: "76px", md: "113px"}} h={{ base: "54px", sm: "54px", md: "80px"}}  borderRadius={8} alt="eReloadSg Logo" /></Link>
            </Center>

            <Center w="60vw" >
                <HStack display={{base:"none", md:"block"}}>

                <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/reload_home" link_text="Buy Now"/>
                {user===null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/create_new_account_main" link_text="Register Account"/>}
                {/**Menu - My Account */}
                <Menu>
                    <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    color='#FFFFFF'
                    background= "#00008B"
                    _hover={{
                        background: "#FFFFFF",
                        color: "blue",
                    }}
                    >
                        <Text fontSize={{ base: "1rem", md: "1rem" }}>
                        My Account
                        </Text>

                    </MenuButton>
                    <MenuList color='blue'>
                        {user===null && <MenuItem><ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/forget_password" link_text="Forget Password" /></MenuItem>}
                        {user!==null && <MenuItem><ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/profile" link_text="My Profile"/></MenuItem>}
                        {user!==null && <MenuItem><ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/change_password" link_text="Change Password"/></MenuItem>}
                        {user!==null && <MenuItem><ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/search_wallet_transaction" link_text="Wallet Transactions"/></MenuItem>}
                        {user!==null && <MenuItem><ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/search_reload" link_text="Reload Transactions"/></MenuItem>}
                        {user!==null && <MenuItem><ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/wallet_home" link_text="Wallet Recharge"/></MenuItem>}
                    </MenuList>
                </Menu>

                <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/contact_us" link_text="Contact Us" onClick={onClose}/>
                {user===null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/login" link_text="Login"/>}
                {user!==null && <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/logout" link_text="Logout"/>}

                </HStack>
            </Center>

            <Center w="20vw" display={{base:"block", md:"none"}}>
                <Button
                as={IconButton}
                aria-label="Menu"
                icon={<HamburgerIcon />}
                variant="outline"
                onClick={onOpen}
                />
            </Center>

            <Center w="20vw" display={{base:"none", md:"block"}}>
              <VStack justifyContent={"center"}>
                <Icon as={VscAccount} w="6" h="6"  color='#FFFFFF' background= "#00008B"/>
                {user!==null && <Text lineHeight={1}>{`${user.username}`}</Text>}
                {(user!==null && wallet!==null && (wallet?.balance>0)) && <Text lineHeight={1}>{`Wallet: $${wallet.balance}`}</Text>}
              </VStack>
            </Center>

            </HStack>
    );

}export default Header;