import * as Yup from "yup";
import {useFormik} from "formik";
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormErrorMessage,
    Container,
  } from "@chakra-ui/react";
  import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
  import {useApiCalls} from "../../hooks/useApiCalls";
  import {useAlertContext} from "../../context/AlertContext";
  import {useEffect} from "react";

function ForgetPasswordResetPasswordForm(props){

    const { onOpen } = useAlertContext();

    const [handleForgetPasswordResetPasswordApiCall,isLoadingForgetPasswordResetPassword, dataForgetPasswordResetPassword, errorForgetPasswordResetPassword] = useApiCalls();

    const POST_FORGET_PASSWORD_RESET_PASSWORD_URL= BASE_URL + "/api/v1/user/forget_password_reset_password2";
    const TITLE_ERROR_FORGET_PASSWORD_RESET_PASSWORD="Error with reset password";
    const TITLE_SUCCESSFUL_FORGET_PASSWORD_RESET_PASSWORD="Reset password successful";
    const DESCRIPTION_SUCCESSFUL_FORGET_PASSWORD_RESET_PASSWORD="Your password has been reset.";

    const passwordFormat = (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$&=!*()<>:;]).{8,12}$/.test(value);
    const passwordMatch = () => formik.values.password1===formik.values.password2;

    const formik = useFormik({
        initialValues: {
          password1: "",
          password2: "",
        },

        onSubmit: (values)=>{
            submitResetPasswordForm(values);
        },

    validationSchema: Yup.object({
        password1:Yup.string()
             .min(8,"Must be at least 6 characters")
             .max(12, "Max is 12 characters")
             .test("Password format", "At least (1) uppercase, (1) lowercase, (1) number and (1) special character[@#$&=!*()<>:;]", passwordFormat)
             .required("Required"),
        password2:Yup.string()
             .min(8,"Must be at least 8 characters")
             .max(12, "Max is 12 characters")
             .test("Password format", "At least (1) uppercase, (1) lowercase, (1) number and (1) special character[@#$&=!*()<>:;]", passwordFormat)
             .test("Password matching", "Passwords must be same", passwordMatch)
             .required("Required"),
      })
    });


    useEffect(()=>{

        if(dataForgetPasswordResetPassword!==null){
            if(dataForgetPasswordResetPassword.content.status==="successful"){
                onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_FORGET_PASSWORD_RESET_PASSWORD, DESCRIPTION_SUCCESSFUL_FORGET_PASSWORD_RESET_PASSWORD);
                props.handleForgetPasswordResetPassword();
            }
        }

    },[dataForgetPasswordResetPassword])


    useEffect(()=>{

        if(errorForgetPasswordResetPassword!==null){

            onOpen(STATUS_ERROR, TITLE_ERROR_FORGET_PASSWORD_RESET_PASSWORD, errorForgetPasswordResetPassword);

        }

    },[errorForgetPasswordResetPassword])


    function submitResetPasswordForm(values){
        //headers
        const config = {
            params:{
                phone_number: props.forgetPasswordVerified.phone_number,
                forget_password_id: props.forgetPasswordVerified.forget_password_id,
                password: values.password2
                }
        };

        //body json params
        const apiJsonParams = {};

        const apiCalls ={
            apiConfig: config,
            url: POST_FORGET_PASSWORD_RESET_PASSWORD_URL,
            apiType: "post",
            apiJsonParams: apiJsonParams
        }

        handleForgetPasswordResetPasswordApiCall({apiCalls});
    }


    return (

        <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth={400}  borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg">

        <Box pt={2} textAlign="center">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Reset Password</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit}>

            <FormControl p={2} isInvalid={(formik.touched.password1 && formik.errors.password1)}>
            <FormLabel>Password</FormLabel>
            <Input 
                type="password" 
                placeholder="*******" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("password1")}
            />
            <FormErrorMessage>{formik.errors.password1}</FormErrorMessage> 
            </FormControl>

            <FormControl  p={2} isInvalid={(formik.touched.password2 && formik.errors.password2)}>
            <FormLabel>Password again</FormLabel>
            <Input 
                type="password" 
                placeholder="*******" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("password2")}
            />
            <FormErrorMessage>{formik.errors.password2}</FormErrorMessage>
            </FormControl>

            <Button width="full" mt={4} type="submit" isLoading={isLoadingForgetPasswordResetPassword}>
            Reset
            </Button>

        </form>
        </Box>
        </Container>

    );

}
export default ForgetPasswordResetPasswordForm;