import {
    Stack,
    StackDivider,
    Heading,
    Box,
    Text,
    Spinner,
    Button,
    Container
} from "@chakra-ui/react"

function ProfileCard(props){

    

    return(

        <Container mb={2} minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth={400} borderWidth={0} borderColor={"blue"} borderRadius={8} boxShadow="lg">

        {props.isLoading && <Spinner size="xl"/>}

            <Box pt={2} textAlign="center">
            <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Profile</Heading>
            </Box>

            <Stack divider={<StackDivider />} spacing="4">
            <Box mt={2}>
                <Text as={"b"} fontSize={{ base: "xs",sm: "sm", md: "md"}}>
                First Name
                </Text>
                <Text pt="2" fontSize={{ base: "1rem", md: "1.5rem" }}>
                {props.user.first_name}
                </Text>
            </Box>
            <Box>
                <Text as={"b"} fontSize={{ base: "xs",sm: "sm", md: "md"}}>
                Last Name
                </Text>
                <Text pt="2" fontSize={{ base: "1rem", md: "1.5rem" }}>
                {props.user.last_name}
                </Text>
            </Box>
            <Box>
                <Text as={"b"} fontSize={{ base: "xs",sm: "sm", md: "md"}}>
                Gender
                </Text>
                <Text pt="2" fontSize={{ base: "1rem", md: "1.5rem" }}>
                {props.user.gender}
                </Text>
            </Box>
            <Box>
                <Text as={"b"} fontSize={{ base: "xs",sm: "sm", md: "md"}}>
                Phone Number
                </Text>
                <Text pt="2" fontSize={{ base: "1rem", md: "1.5rem" }}>
                {props.user.phone_num}
                </Text>
            </Box>

            <Box>
                <Text as={"b"} fontSize={{ base: "xs",sm: "sm", md: "md"}}>
                Email
                </Text>
                <Text pt="2" fontSize={{ base: "1rem", md: "1.5rem" }}>
                {props.user.email}
                </Text>
            </Box>

            <Button width="full" mt={4} mb={4} onClick={props.allowEdit}>
            Edit
            </Button>

            </Stack>

        </Container>

    );
}
export default ProfileCard;