import {createContext, useContext, useState, useEffect} from "react";
import {useUser} from "../context/UserContext";
import {useAlertContext} from "../context/AlertContext";
import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR } from '../utility/Constants';
import {useApiCalls} from "../hooks/useApiCalls"

const ReloadContext = createContext(undefined);

export function useReload() {
        return useContext(ReloadContext);
    }

export function ReloadProvider({ children }) {

    const {authorizationToken} = useUser();
    const { onOpen } = useAlertContext();

    const [userId,setUserId] = useState(null);  //login user data
    //const [completeLoading, setCompleteLoading] = useState(false);
    const [priceGroupId,setPriceGroupId] = useState(null);
    const [telecoms,setTelecoms] = useState(null);
    const [telecomValues, setTelecomValues] = useState(null);
    const [reloadValues, setReloadValues] = useState(null);

    const TITLE_ERROR_GET_USER_RELOAD_VALUE_LIST="Error when loading user reload values list !";
    const TITLE_SUCCESSFUL_GET_USER_RELOAD_VALUE_LIST="Get user reload values list successful";
    const DESCRIPTION_SUCCESSFUL_GET_USER_RELOAD_VALUE_LIST="Get user reload values list successful";
    
    const GET_USER_RELOAD_VALUE_LIST_URL= BASE_URL + "/api/v1/user/get_user_reload_value_list";
    const GET_PUBLIC_USER_RELOAD_VALUE_LIST_URL = BASE_URL + "/api/v1/user/get_public_reload_value_list";

    const [handleGetUserReloadValueListApiCall,isLoadingGetUserReloadValueList, dataGetUserReloadValueList, errorGetUserReloadValueList] = useApiCalls();


   useEffect(()=>{

        if(authorizationToken!==null){
            getUserReloadValueListApiCall();
        }else{
            getPublicUserReloadValueListApiCall(); 
        }

    },[authorizationToken])


   /*  useEffect(()=>{
    console.log("authorizationToken "+authorizationToken);
        getUserReloadValueListApiCall();
    },[])*/


    useEffect(()=>{
        if(dataGetUserReloadValueList!==null){
            if(dataGetUserReloadValueList.content.status==="successful"){
                setUserId(dataGetUserReloadValueList.content.user_id);
                setPriceGroupId(dataGetUserReloadValueList.content.price_group_id);
                setTelecoms(dataGetUserReloadValueList.content.telecom_list);
                setTelecomValues(dataGetUserReloadValueList.content.telecom_value_list);
                setReloadValues(dataGetUserReloadValueList.content.reload_value_list);
            }
        }
    },[dataGetUserReloadValueList])

    useEffect(()=>{
        if(errorGetUserReloadValueList!==null){
            onOpen(STATUS_ERROR, TITLE_ERROR_GET_USER_RELOAD_VALUE_LIST, errorGetUserReloadValueList);
        }
    },[errorGetUserReloadValueList])


    function getUserReloadValueListApiCall(){

         //headers
         const config = {
            headers:{
                "Authorization": `Bearer ${authorizationToken}`
            }
        };

        //body json params
        const apiJsonParams = {};

        const apiCalls ={
            apiConfig: config,
            url: GET_USER_RELOAD_VALUE_LIST_URL,
            apiType: "get",
            apiJsonParams: apiJsonParams
        }

        handleGetUserReloadValueListApiCall({apiCalls});//call api

    }

    function getPublicUserReloadValueListApiCall(){

        //headers
        const config = {};

       //body json params
       const apiJsonParams = {};

       const apiCalls ={
           apiConfig: config,
           url: GET_PUBLIC_USER_RELOAD_VALUE_LIST_URL,
           apiType: "get",
           apiJsonParams: apiJsonParams
       }

       //console.log("getPublicUserReloadValueListApiCall: " + JSON.stringify(apiCalls));
       handleGetUserReloadValueListApiCall({apiCalls});//call api

   }


    return (

    <ReloadContext.Provider value={{ userId, isLoadingGetUserReloadValueList ,priceGroupId, telecoms, telecomValues, reloadValues }}>
        {children}
    </ReloadContext.Provider>

    );



  }