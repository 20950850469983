import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useAlertContext} from "../../context/AlertContext";
import {useUser} from "../../context/UserContext";
import {useState,useEffect} from "react";
import {
    Spinner,
    Flex,
    Container,
    Text,
    Box,
    Button,
    Heading
 } from "@chakra-ui/react"
import {ArrowLeftIcon} from "@chakra-ui/icons";


function Receipt(props){

    const {authorizationToken,removeUser} = useUser();
    const { onOpen } = useAlertContext();

    const date_locales="sg";
    const date_options=null; //{"hour12": false};

    const [order,setOrder] = useState(null);
    const [orderItems,setOrderItems] = useState(null);

    const TITLE_ERROR_GET_ORDER="Error get order";
    //const TITLE_SUCCESSFUL_CHANGE_PASSWORD="Change password successful";
    //const DESCRIPTION_SUCCESSFUL_CHANGE_PASSWORD="Your password has been changed.";

    const [handleGetOrderApiCall,isLoadingGetOrder, dataGetOrder, errorGetOrder] = useApiCalls();

    const GET_ORDER_URL = BASE_URL+ "/api/v1/reload/get_order";

    useEffect(()=>{

            getOrderApiCall();

    },[]);


    useEffect(()=>{

        if(dataGetOrder!==null){
            if(dataGetOrder.content.status==="successful"){
               setOrder(dataGetOrder.content.order);
               setOrderItems(dataGetOrder.content.order_items);
            }
        }

    },[dataGetOrder])


    useEffect(()=>{

        if(errorGetOrder!==null){

            if(errorGetOrder==="unauthorized_access"){
                onOpen(STATUS_ERROR, TITLE_ERROR_GET_ORDER,"Login expired, please login again");
                removeUser();
            }else{
            onOpen(STATUS_ERROR, TITLE_ERROR_GET_ORDER, errorGetOrder);
            }
        }

    },[errorGetOrder])


     //api calls
     function getOrderApiCall(){

        //headers
        const config = {
            headers:{
                "Authorization": `Bearer ${authorizationToken}`,
            }
        }

        //body json params
        const apiJsonParams = {};

        const apiCalls ={
            apiConfig: config,
            url: GET_ORDER_URL+"/"+props.receiptOrderId,
            apiType: "get",
            apiJsonParams: apiJsonParams
        }

        handleGetOrderApiCall({apiCalls});
    }


    return(

        <>
            <Flex mb={4} ml={2}><Button leftIcon={<ArrowLeftIcon />} colorScheme="blue" variant="solid" size="sm" onClick={()=>{props.handleGetReceipt(null)}} >Back</Button></Flex>

            <Container mb={2} minWidth={{ base: "200px",sm: "350px", md: "400px"}} borderWidth={0} borderColor={"blue"} borderRadius={8} boxShadow="lg">

            <Box textAlign="center" borderWidth={0} borderRadius={8} boxShadow="lg">
            <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Order Receipt</Heading>
            </Box>



            <Flex mt={5} mb={5} justifyContent={"space-between"}>
            <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }}>Date: {new Date(order?.createdAt).toLocaleString({date_locales}, {date_options})}</Text>
            <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }}>Order Id: #{order?.id}</Text>
            </Flex>

            

            {
                orderItems?.map((orderItem,i)=>

                    <Box mb={5}>
                        <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Item Id: #{orderItem.id}</Text>
                        <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Telecom: {orderItem.itemTelecom}</Text>
                        <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Item Name: {orderItem.itemName}</Text>
                        <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Phone Number: {orderItem.phoneNumber}</Text>
                        <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Amount($): {orderItem.itemSellPrice}</Text>
                    </Box>

                )

            }

            <Flex justifyContent={"left"}>
            <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Total($): {order?.totalAmount}</Text>
            </Flex>

            <Box pt={10} pb={5} textAlign={"center"}>
                <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Easy Reload System Pte Ltd</Text>
                <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>71 Pioneer Road</Text>
                <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>#01-K2</Text>
                <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>Singapore 639591</Text>
                <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>https://www.ereloadsg.com</Text>
            </Box>



            </Container>

        </>
    )


}
export default Receipt;