import {
    Text,
    Heading,
    Box,
    Button,
    Center,
    Container
} from "@chakra-ui/react";




function WalletPaymentConfirmation(props){


return(
    <Container mb={2} minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth={400} borderWidth={0} borderColor={"blue"} borderRadius={8} boxShadow="lg">

        <Box textAlign="center" >
        <Heading size="xl">Confirm top up to Wallet?</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <Box justifyContent={"center"} borderWidth={0} borderColor={"black"} minWidth={100} >
        <Heading mt={{ base: "5px",sm: "10px"}} size="md" textAlign={"center"}>Payment Amount</Heading>

            <Center>
            <Text fontSize="3xl" color="blue">${props.amount}</Text>
            </Center>

        </Box>


        <Button width="full" mt={4} type="submit" variant="solid" colorScheme="blue" onClick={props.handleWalletPaymentConfirmation}>
        Payment by PayNow
        </Button>
        
        <Button width="full" mt={4} mb={4} onClick={props.cancelConfirmTopUp}>
        Cancel
        </Button>



        </Box>

        </Container>

);


}
export default WalletPaymentConfirmation;