import CreateNewAccount from "./CreateNewAccount";
import CreateNewAccountVerifyOtp from "./CreateNewAccountVerifyOtp";
import {useState} from "react";



function CreateNewAccountMain(){

    const [newUser, setNewUser] = useState(null);

    const handleVerifyOtp=(user) => {setNewUser(user);}


    return(

        <>
        {/**create account */}
        {(!newUser) && <CreateNewAccount handleVerifyOtp={handleVerifyOtp}/>}

        {/**verify otp */}
        {(newUser) && <CreateNewAccountVerifyOtp newUser={newUser}/>}
        </>

    );

}
export default CreateNewAccountMain;