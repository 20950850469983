import {
    SimpleGrid,
    Container,
    Flex,
    Box,
    Button,
    Heading
 } from "@chakra-ui/react";
 import {ArrowLeftIcon} from "@chakra-ui/icons";
 import ReloadValueCard from "./ReloadValueCard";


function SelectReloadValue(props){

    //telecomValues={telecomValues} reloadValues={reloadValues} selectReloadValueId={selectReloadValueId}
    //const telecoms = props.telecoms;
    const telecomValues = props.telecomValues;
    const reloadValues = props.reloadValues;

    function getReloadValueList(telecom_id){
        return telecomValues.filter(telecomValue => telecomValue.telecomId===telecom_id);
    }

    function selectReloadValueId(id){
        props.selectReloadValueId(id);
    }


return(

    <>

    <Flex mb={4} ml={2}><Button leftIcon={<ArrowLeftIcon />} colorScheme="blue" variant="solid" size="sm" onClick={()=>{props.selectTelecomId(null)}} >Back</Button></Flex>

    <Box textAlign="center" borderWidth={0} borderRadius={8} boxShadow="lg">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Select a Value to Top Up</Heading>
    </Box>

   <SimpleGrid minChildWidth={{ base: "100px", sm:"140px", md: "180px"}}  spacing="10px" m={5} >

        {
            getReloadValueList(props.selectedTelecomId)?.map((telecomValue,i)=>


            <ReloadValueCard key={i} telecomValue={telecomValue} selectReloadValueId={selectReloadValueId} reloadValue={reloadValues.find(reloadValue => reloadValue.telecomValueId === telecomValue.id)}/>

            )
        }

    </SimpleGrid>

   </>

);


}
export default SelectReloadValue;
