import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useAlertContext} from "../../context/AlertContext";
import {useEffect,useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import { v4 as uuidv4 } from "uuid";
import {
    HStack,
    Flex,
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormErrorMessage,
    Container
  } from "@chakra-ui/react";


function CreateNewAccount(props)
{
    const { onOpen } = useAlertContext();

    const TITLE_SUCCESSFUL_CREATE_ACCOUNT="Verify your Account";
    const TITLE_ERROR_CREATE_ACCOUNT="Error when creating account";
    const DESCRIPTION_SUCCESSFUL_CREATE_ACCOUNT="Please check your SMS for OTP to verify your account.";

    const [verificationUuid, setVerificationUuid] = useState("");

    const CREATE_ACCOUNT_URL= BASE_URL+ "/api/v1/user/create_account_request_otp";

    const [handleCreateAccountApiCall,isLoadingCreateAccount, dataCreateAccount, errorCreateAccount] = useApiCalls();

    const digitsOnly = (value) => /^\d+$/.test(value);
    const sgNumberOnly = (value) => /^(8|9)\d{7}$/.test(value);
    const passwordFormat = (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$&=!*()<>:;]).{8,12}$/.test(value);
    const passwordMatch = () => formik.values.password1===formik.values.password2;

    const formik = useFormik({
        initialValues: {
          phoneNum:"",
          password1: "",
          password2: ""
        },

        onSubmit: (values)=>{
            submitCreateAccountForm(values);
        },

    validationSchema: Yup.object({
        phoneNum: Yup.string()
             .required("Required")
             .typeError("Must be 8 digit phone number")
             .test("Digits only", "Must be 8 digit phone number", digitsOnly)
             .test("Singapore mobile number only", "SG number starts with (8) or (9) and length of (8) digits", sgNumberOnly),
        password1:Yup.string()
             .min(8,"Must be at least 6 characters")
             .max(12, "Max is 12 characters")
             .test("Password format", "At least (1) uppercase, (1) lowercase, (1) number and (1) special character[@#$&=!*()<>:;]", passwordFormat)
             .required("Required"),
        password2:Yup.string()
             .min(8,"Must be at least 8 characters")
             .max(12, "Max is 12 characters")
             .test("Password format", "At least (1) uppercase, (1) lowercase, (1) number and (1) special character[@#$&=!*()<>:;]", passwordFormat)
             .test("Password matching", "Passwords must be same", passwordMatch)
             .required("Required"),
      })
    });


    useEffect(()=>{

      if(verificationUuid===""){

          setVerificationUuid(uuidv4());
      }

  },[verificationUuid])



  useEffect(()=>{

    if(dataCreateAccount!==null){
        if(dataCreateAccount.content.status==="successful"){
            onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_CREATE_ACCOUNT, DESCRIPTION_SUCCESSFUL_CREATE_ACCOUNT);

            const user = {
              phone_number: formik.values.phoneNum,
              verification_uuid: verificationUuid,
              password: formik.values.password2
            }
            props.handleVerifyOtp(user);
        }
    }

  },[dataCreateAccount])


  useEffect(()=>{

        if(errorCreateAccount!==null){
            onOpen(STATUS_ERROR, TITLE_ERROR_CREATE_ACCOUNT, errorCreateAccount);
        }

  },[errorCreateAccount])


    function submitCreateAccountForm(values){

       //headers
      const config = {
        headers:{},
        params:{}
      };

      //body json params
      const apiJsonParams = {
        phone_number: values.phoneNum,
        verification_uuid: verificationUuid
      };

      const apiCalls ={
          apiConfig: config,
          url: CREATE_ACCOUNT_URL,
          apiType: "post",
          apiJsonParams: apiJsonParams
      }

      handleCreateAccountApiCall({apiCalls});
  }

 
    return(


      <Container maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }} borderWidth={0} borderColor={"blue"} align="top" minHeight="70vh" justifyContent="center">

      <Flex direction="column" justifyContent="center" >

      <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth="400px" mb={2} borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg"> 

        <Box pt={2} textAlign="center">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Create new account</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit}>

            <FormControl p={2} isInvalid={(formik.touched.phoneNum && formik.errors.phoneNum)}>
            <FormLabel>Phone number</FormLabel>
            <Input 
                type="number" 
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("phoneNum")}
            />
            <FormErrorMessage>{formik.errors.phoneNum}</FormErrorMessage>
            </FormControl>

           

            <FormControl p={2} isInvalid={(formik.touched.password1 && formik.errors.password1)}>
            <FormLabel>Password</FormLabel>
            <Input 
                type="password" 
                placeholder="*******" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("password1")}
            />
            <FormErrorMessage>{formik.errors.password1}</FormErrorMessage> 
            </FormControl>

            <FormControl  p={2} isInvalid={(formik.touched.password2 && formik.errors.password2)}>
            <FormLabel>Password again</FormLabel>
            <Input 
                type="password" 
                placeholder="*******" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("password2")}
            />
            <FormErrorMessage>{formik.errors.password2}</FormErrorMessage>
            </FormControl>

        <Button width="full" mt={4} type="submit" isLoading={isLoadingCreateAccount}>
        Create
        </Button>

        </form>
    </Box>

    </Container>
    </Flex>
    </Container>

    );
}
export default CreateNewAccount;