import { 
    Box,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Spinner,
    Button,
    Container,
    Text,
    Spacer,
    Divider,
    Stack
 } from "@chakra-ui/react"
import {useApiCalls} from "../../hooks/useApiCalls";
import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useAlertContext} from "../../context/AlertContext";
import {useUser} from "../../context/UserContext";
import {useState,useEffect} from "react";
import { FaEdit } from "react-icons/fa";
import OrderItemCard from "./OrderItemCard";


function UserReloadStatus(props){

    const {authorizationToken,removeUser} = useUser();
    const {onOpen} = useAlertContext();

    const [handleGetOrderItemListApiCall,isLoadingGetOrderItemList, dataGetOrderItemList, errorGetOrderItemList] = useApiCalls();

    const [orderItemList, setOrderItemList] = useState(null);

    const TITLE_ERROR_GET_ORDER_ITEM_LIST="Error when get order item list !";
    const GET_ORDER_ITEM_LIST_URL = BASE_URL + "/api/v1/reload/get_order_item_list";

    const date_locales="sg";
    const date_options=null; //{"hour12": false};

    //run every 30000ms
    useEffect(() => {

        const interval = setInterval(() => {
            setOrderItemList(null);
        }, 10000);

        return () => clearInterval(interval);

    }, []);


    useEffect(()=>{

        if(orderItemList===null){
            getOrderItemListApiCall();
        }

    },[orderItemList])


    useEffect(()=>{
        if(dataGetOrderItemList!==null){
            if(dataGetOrderItemList.content.status==="successful"){
                //onOpen(STATUS_SUCCESS,TITLE_SUCCESSFUL_GET_ORDER_ITEM_LIST,DESCRIPTION_SUCCESSFUL_GET_ORDER_ITEM_LIST);
                setOrderItemList(dataGetOrderItemList.content.order_items);
            }
        }
    },[dataGetOrderItemList])


    useEffect(()=>{
        if(errorGetOrderItemList!==null){

            if(errorGetOrderItemList==="unauthorized_access"){
                onOpen(STATUS_ERROR,TITLE_ERROR_GET_ORDER_ITEM_LIST,"Login expired, please login again");
                removeUser();
            }else{
               // onOpen(STATUS_ERROR,TITLE_ERROR_GET_ORDER_ITEM_LIST,errorGetOrderItemList);
            }

        }
    },[errorGetOrderItemList])


    function getOrderItemListApiCall(){

            //headers
        const config = {
            headers:{
                "Authorization": `Bearer ${authorizationToken}`,
            }, 
            params:{
                page: 0,
                size: 5, //5 in 1 page
                }
          };

        const apiCalls ={
            apiConfig: config,
            url: GET_ORDER_ITEM_LIST_URL,
            apiType: "get",
            apiJsonParams: {}
        } 

        handleGetOrderItemListApiCall({apiCalls});//call api
    }

    const handleGetReceipt = (e)=>{
        props.handleGetReceipt(e.target.value);
    }

    return(
        
        <>
        { isLoadingGetOrderItemList && <Spinner size="xs"/> }

        <Box textAlign="center" mt={10} mb={2} borderWidth={1} borderRadius={8} boxShadow="lg">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }} display={"inline"}>Last 5 Reloads</Heading>
        </Box>
{/*
        <Box textAlign="left" >


            <TableContainer >

                <Table size="sm" variant="striped" mb={4}>

                    <Thead>
                    <Tr>
                        <Th>#Id</Th>
                        <Th>Telecom</Th>
                        <Th>Name</Th>
                        <Th>Phone Number</Th>
                        <Th>Price</Th>
                        <Th>Status</Th>
                        <Th>Remarks</Th> 
                        <Th>Date</Th>
                    </Tr>
                    </Thead>

                    <Tbody>

                    {
                        orderItemList?.map((orderItem)=>(
                            <Tr key={orderItem.id}>
                            <Td>
                                <Text mr={2} display="inline" fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.id}</Text>
                                {orderItem.status==="successful" && (<Button value={orderItem.order_id} onClick={handleGetReceipt} colorScheme='blue' variant='solid' size={{ base: "xs", sm: "sm", md: "sm" }} >
                                    Receipt
                                </Button>)}
                            </Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.item_telecom}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.item_name}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.phone_number}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.item_sell_price}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.status}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.remarks}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{new Date(orderItem.created_at).toLocaleString({date_locales}, {date_options})}</Text></Td>
                            </Tr>
                        ))
                    }

                    </Tbody>

                </Table>
            </TableContainer>
        </Box>
        */}

        {
            <Stack mb={4} spacing={4}>

            {
                orderItemList?.map((item)=>
                    <OrderItemCard orderItem={item} telecomValues={props.telecomValues} telecoms={props.telecoms} handleGetReceipt={handleGetReceipt}/>
                )
            }

            </Stack>
        }

        </>
    );
}
export default UserReloadStatus;