
import { Navigate } from "react-router-dom";


function ProtectedRouteNotForLogonUser({user,children}){

    return (
        <>
            {
               user===null ? children : <Navigate to="/" />
            }
        </>
    );
}
export default ProtectedRouteNotForLogonUser;