import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useAlertContext} from "../../context/AlertContext";
import {useUser} from "../../context/UserContext";
import {useEffect} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {
    HStack,
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormErrorMessage,
    RadioGroup,
    Radio,
    Container
  } from "@chakra-ui/react";



function EditUserForm(props){

    const {authorizationToken,removeUser} = useUser();
    const { onOpen } = useAlertContext();

    const digitsOnly = (value) => /^\d+$/.test(value);
    const sgNumberOnly = (value) => /^(8|9)\d{7}$/.test(value);

    const TITLE_ERROR_UPDATE_USER="Error updating user";
    const TITLE_SUCCESSFUL_UPDATE_USER="Update successful";
    const DESCRIPTION_SUCCESSFUL_UPDATE_USER="Your profile has been updated.";

    const [handleUpdateUserApiCall,isLoadingUpdateUser, dataUpdateUser, errorUpdateUser] = useApiCalls();

    const POST_UPDATE_USER_URL = BASE_URL+ "/api/v1/user/update_user";

    const formik = useFormik({
        initialValues: {
          firstName: props.user.first_name,
          lastName: props.user.last_name,
          gender:props.user.gender,
          phoneNum:props.user.phone_num,
          email: props.user.email,
          
        },
    
        onSubmit: (values)=>{
          updateUserApiCall(values);
        },

    validationSchema: Yup.object({
        firstName: Yup.string()
             .min(1,"Must be at least 1 character")
             .max(50, "Must be 50 characters or less")
             .required("Required"),
        lastName: Yup.string()
             .min(1,"Must be at least 1 character")
             .max(50, "Must be 50 characters or less")
             .required("Required"),
        gender: Yup.string()
             .required("Required"),
        phoneNum: Yup.string()
             .required("Required")
             .typeError("Must be 8 digit phone number")
             .test("Digits only", "Must be 8 digit phone number", digitsOnly)
             .test("Singapore mobile number only", "SG number starts with (8) or (9) and length of (8) digits", sgNumberOnly),
        email: Yup.string()
             .email("Invalid email address")
             .required("Required")
      })
    });


    useEffect(()=>{

      if(dataUpdateUser!==null){
          if(dataUpdateUser.content.status==="successful"){
              onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_UPDATE_USER, DESCRIPTION_SUCCESSFUL_UPDATE_USER);
              props.processUpdateUserSuccess(formik.values, dataUpdateUser.content.authorization);
          }
      }

    },[dataUpdateUser])


    useEffect(()=>{

        if(errorUpdateUser!==null){

          if(errorUpdateUser==="unauthorized_access"){
            onOpen(STATUS_ERROR, TITLE_ERROR_UPDATE_USER,"Login expired, please login again");
            removeUser();
          }else{
            onOpen(STATUS_ERROR, TITLE_ERROR_UPDATE_USER, errorUpdateUser);
          }

        }

    },[errorUpdateUser])


    //api calls
    function updateUserApiCall(values){

      //headers
      const config = {
        headers:{
            Authorization: `Bearer ${authorizationToken}`
        },
        params:{
            first_name: values.firstName,
            last_name: values.lastName,
            gender: values.gender,
            phone_num: values.phoneNum,
            email: values.email.toLowerCase(),
            }
      };

      //body json params
      const apiJsonParams = {};

      const apiCalls ={
          apiConfig: config,
          url: POST_UPDATE_USER_URL,
          apiType: "post",
          apiJsonParams: apiJsonParams
      }

      handleUpdateUserApiCall({apiCalls});
  }



    return(

      <Container mb={2} minWidth={{ base: "200px",sm: "350px", md: "400px"}}  maxWidth={400}  borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg">

        <Box pt={2} textAlign="center">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Edit Profile</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit}>

            <FormControl p={2} isInvalid={(formik.touched.firstName && formik.errors.firstName)} >
            <FormLabel>First name</FormLabel>
            <Input 
                type="text" 
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("firstName")}
            />
            <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            </FormControl>

            <FormControl p={2} isInvalid={(formik.touched.lastName && formik.errors.lastName)}>
            <FormLabel>Last name</FormLabel>
            <Input 
                type="text" 
                placeholder="" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("lastName")}
            />
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            </FormControl>
            
            <FormControl p={2}  isInvalid={(formik.touched.gender && formik.errors.gender)} >
            <FormLabel>Gender</FormLabel>
            <RadioGroup defaultValue={formik.values.gender}>
              <HStack>
              <Radio 
                value="male"
                name="gender"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >Male</Radio>
              <Radio 
                value="female"
                name="gender"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >Female</Radio>
              </HStack>
            </RadioGroup>
            <FormErrorMessage>{formik.errors.gender}</FormErrorMessage>
            </FormControl>

            <FormControl p={2} isInvalid={(formik.touched.phoneNum && formik.errors.phoneNum)}>
            <FormLabel>Phone number</FormLabel>
            <Input 
                type="number" 
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("phoneNum")}
            />
            <FormErrorMessage>{formik.errors.phoneNum}</FormErrorMessage>
            </FormControl>

            <FormControl p={2} isInvalid={(formik.touched.email && formik.errors.email)} >
            <FormLabel>Email</FormLabel>
            <Input 
                type="email" 
                placeholder="hello@hello.com" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("email")}
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <Button width="full" mt={4} type="submit" isLoading={isLoadingUpdateUser}>
            Save
            </Button>
            <Button width="full" mt={4} onClick={props.cancelEdit} >
            Cancel
            </Button>

        </form>
    </Box>

    </Container>

    );
}
export default EditUserForm;