import {useUser} from "../../context/UserContext";
import {useAlertContext} from "../../context/AlertContext";
import {useApiCalls} from "../../hooks/useApiCalls";
import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useState,useEffect} from "react";
import SearchWalletTransactionForm from "./SearchWalletTransactionForm";
import {
    Box,
    Container,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Heading
 } from "@chakra-ui/react";

function SearchWalletTransactionMain(){

    const {authorizationToken,removeUser} = useUser();
    const {onOpen} = useAlertContext();
    const [walletTransactionResult,setWalletTransactionResult] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const size=10;

    //const todayDate=new Date().toISOString().split("T")[0]; //get date string as 2023-12-25

    /*const defaultSearchValues=
        {
            start_date: todayDate,
            end_date: todayDate,
            current_page: 0,
            total_page: 0,
            total_item: 0,
            size: 5
        };*/

    const [searchValues, setSearchValues] = useState(null);

    const updateSearchValues = (values)=>{setSearchValues(values)};

    const TITLE_ERROR_GET_WALLET_TRANSACTION_LIST="Error get wallet transactions !";

    const [handleGetWalletTransactionListApiCall,isLoadingGetWalletTransactionList, dataGetWalletTransactionList, errorGetWalletTransactionList] = useApiCalls();

    const GET_WALLET_TRANSACTION_LIST_URL= BASE_URL+ "/api/v1/user/get_wallet_transaction_list";

    const date_locales="sg";
    const date_options=null; //{"hour12": false};


 
    useEffect(()=>{

        if(searchValues!==null){
            getWalletTransactionListApiCall();
        }

    },[searchValues,currentPage]);


    useEffect(()=>{

        if(dataGetWalletTransactionList!==null){
            if(dataGetWalletTransactionList.content.status==="successful"){
                setWalletTransactionResult(dataGetWalletTransactionList.content.wallet_transactions);
                const current_page = dataGetWalletTransactionList.content.current_page;
                const total_pages = dataGetWalletTransactionList.content.total_pages;
                const total_items = dataGetWalletTransactionList.content.total_items;
                setCurrentPage(current_page);
                setTotalPages(total_pages);
                setTotalItems(total_items);
            }
        }

    },[dataGetWalletTransactionList])


    useEffect(()=>{

        if(errorGetWalletTransactionList!==null){

            if(errorGetWalletTransactionList==="unauthorized_access"){
                onOpen(STATUS_ERROR, TITLE_ERROR_GET_WALLET_TRANSACTION_LIST,"Login expired, please login again");
                removeUser();
            }else{
            onOpen(STATUS_ERROR, TITLE_ERROR_GET_WALLET_TRANSACTION_LIST, errorGetWalletTransactionList);
            }
        }

    },[errorGetWalletTransactionList])


     //api calls
     function getWalletTransactionListApiCall(){

        //headers
        const config = {
            headers:{
                "Authorization": `Bearer ${authorizationToken}`,
            }, 
            params:{
                start_date: searchValues.start_date,
                end_date: searchValues.end_date,
                page: currentPage,
                size: size
                }
            }

        //body json params
        const apiJsonParams = {};

        const apiCalls ={
            apiConfig: config,
            url: GET_WALLET_TRANSACTION_LIST_URL,
            apiType: "get",
            apiJsonParams: apiJsonParams
        }

        handleGetWalletTransactionListApiCall({apiCalls});
    }

    function handlePage(e){

        if(currentPage==e.target.value){
            //current page, no need do anything
        }
        else{
            setCurrentPage(e.target.value);
        }
    }
    
    function handlePreviousPage(e){
    
        if( currentPage==0){
        }
        else if( (totalPages-currentPage) >0){
            setCurrentPage(currentPage-1);
        }
    
    }
    
    function handleNextPage(e){
    
        if( (currentPage+1)===totalPages){
        }
        else if(currentPage < totalPages){
            setCurrentPage(currentPage+1);
        }
    }


    const pagination=(

        <>
            <Box pb={5}>
        
                <Box 
                as="button"
                height='24px'
                mr={2}    
                ml={2}
                border='1px'
                px='8px'
                borderRadius='50%'
                fontSize='14px'
                bg="#FFFFFF"
                borderColor='#ccd0d5'
                color='#4b4f56'
                _hover={{ bg: '#ebedf0' }}
                onClick={handlePreviousPage}
                >
                 {"<"}
                </Box>
        
        
            {
                [...Array(totalPages)].map( (e, i) => 
                
                
                
                <Box 
                key={i}
                as="button"
                height='24px'
                mr={2}    
                ml={2}
                border='1px'
                px='8px'
                borderRadius='50%'
                fontSize='14px'
                bg={currentPage==i ? '#ccd0d5' : "#FFFFFF" }
                borderColor='#ccd0d5'
                color='#4b4f56'
                _hover={{ bg: '#ebedf0' }}
                value={i} 
                onClick={handlePage}
                >
                { i+1 }
                </Box>
        
        
                )
            }
                <Box 
                as="button"
                height='24px'
                mr={2}    
                ml={2}
                border='1px'
                px='8px'
                borderRadius='50%'
                fontSize='14px'
                bg="#FFFFFF"
                borderColor='#ccd0d5'
                color='#4b4f56'
                _hover={{ bg: '#ebedf0' }}
                onClick={handleNextPage}
                >
                {">"}
                </Box>
        
            </Box>
        
        </>
        
        )


    const listWalletTransactions= (
        <>
            <Box textAlign="center">
            <Heading size={{ base: "sm", sm: "md", md: "lg" }} >Wallet Transactions</Heading>
            </Box>

            <Box textAlign="left" ml={4}>
            <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{`Total: ${totalItems}`}</Text>
            </Box>

            <Box my={4} textAlign="left" >

            <TableContainer >

                <Table size='sm' variant="striped" >

                    <Thead>
                    <Tr>
                        <Th>Type</Th>
                        <Th>Purpose</Th>
                        <Th>Amount</Th>
                        <Th>After Balance</Th>
                        <Th>#Item Id</Th>
                        <Th>Remarks</Th>
                        <Th>Date</Th>
                    </Tr>
                    </Thead>

                    <Tbody>

                    {
                        walletTransactionResult?.map((walletTransaction)=>(
                            <Tr key={walletTransaction.id}>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{walletTransaction.type}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{walletTransaction.purpose}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{walletTransaction.amount}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{walletTransaction.afterBalance}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{walletTransaction.orderItemId}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{walletTransaction.remarks}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{new Date(walletTransaction.createdAt).toLocaleString({date_locales}, {date_options})}</Text></Td>
                            </Tr>
                        ))
                    }
    
                    </Tbody>
    
                </Table>
                </TableContainer>
            </Box>
            </>
    )





    return(

    <Container maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }} borderWidth={0} borderColor={"blue"}  align="top" minHeight="70vh" justifyContent="center">

        {/**searchForm */}
        <SearchWalletTransactionForm searchValues={searchValues} updateSearchValues={updateSearchValues} isLoading={isLoadingGetWalletTransactionList} />


        <Container mt={2} mb={2} maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }} borderWidth={0} borderColor={"blue"} borderRadius={8} boxShadow="lg">
            {/**search result table */}
            {(walletTransactionResult!==null) && listWalletTransactions}
            {(walletTransactionResult!==null) && pagination}

        </Container>

    </Container>

    );

}
export default SearchWalletTransactionMain;