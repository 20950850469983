import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useAlertContext} from "../../context/AlertContext";
import ProfileCard from "./ProfileCard";
import EditUserForm from "./EditUserForm";
import {useUser} from "../../context/UserContext";
import {useState,useEffect} from "react";
import {
    Flex,
    Box,
    Container,
    Spinner
 } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


function UserProfile(){

    const {authorizationToken,saveAuthorizationToken,removeUser} = useUser();
    const {onOpen} = useAlertContext();
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const TITLE_ERROR_GET_USER="Error get user !";
    //const TITLE_SUCCESSFUL_GET_USER="Successful get user.";
    //const DESCRIPTION_SUCCESSFUL_GET_USER="Successful get user.";

    const [handleGetUserApiCall,isLoadingGetUser, dataGetUser, errorGetUser] = useApiCalls();

    const GET_USER_URL= BASE_URL+ "/api/v1/user/get_user";


    useEffect(()=>{

        if(authorizationToken!==null){
            getUserApiCall();
        }

    },[authorizationToken]);


    useEffect(()=>{

        if(dataGetUser!==null){
            if(dataGetUser.content.status==="successful"){
                //onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_GET_USER, DESCRIPTION_SUCCESSFUL_GET_USER);
                setUser(dataGetUser.content.user);
            }
        }

    },[dataGetUser])


    useEffect(()=>{

        if(errorGetUser!==null){

            navigate("/");  //back to home

            if(errorGetUser==="unauthorized_access"){
                onOpen(STATUS_ERROR, TITLE_ERROR_GET_USER,"Login expired, please login again");
                removeUser();
            }else{
            onOpen(STATUS_ERROR, TITLE_ERROR_GET_USER, errorGetUser);
            }
        }

    },[errorGetUser])


    //api calls
    function getUserApiCall(){

        //headers
        const config = {
            headers:{
                "Authorization": `Bearer ${authorizationToken}`
            }
        };

        //body json params
        const apiJsonParams = {};

        const apiCalls ={
            apiConfig: config,
            url: GET_USER_URL,
            apiType: "get",
            apiJsonParams: apiJsonParams
        }

        handleGetUserApiCall({apiCalls});
    }


  //update the telecom cost in the list
  function processUpdateUserSuccess(values,newAuthorizationToken){

    if(values!==null){

        //update user
        const updatedUser ={...user,
            first_name:values.firstName,
            last_name: values.lastName,
            gender: values.gender,
            phone_num: values.phone_num,
            email: values.email.toLowerCase()
        }
        setUser(updatedUser);
        saveAuthorizationToken(newAuthorizationToken);
        setIsEdit(false);

    }
  }


    return(

        <Container maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }}  borderWidth={0} borderColor={"blue"}  align="top" minHeight="70vh" justifyContent="center">

            <Flex direction="column" justifyContent="center" >

            {isLoadingGetUser && <><Spinner size="xl"/> <p>Loading...</p></>}

            { (user!==null && isEdit===false && errorGetUser===null) && <ProfileCard user={user} isLoading={isLoadingGetUser} allowEdit={()=>setIsEdit(true)} /> }
            { (user!==null && isEdit) && <EditUserForm user={user} cancelEdit={()=>setIsEdit(false)} processUpdateUserSuccess={processUpdateUserSuccess} /> } 

            </Flex>

        </Container>

    );

}
export default UserProfile;