import WalletPaymentForm from "./WalletPaymentForm";
import WalletPaymentConfirmation from "./WalletPaymentConfirmation";
import WalletPaymentPaynow from "./WalletPaymentPaynow";

import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useAlertContext} from "../../context/AlertContext";
import {useState,useEffect} from "react";
import {
    Container
 } from "@chakra-ui/react"

function WalletHome(){

    const [amount,setAmount] = useState(null);
    const [confirmTopUp, setConfirmTopUp] = useState(null)

    function handleWalletPaymentAmount(amt){
        setAmount(amt);
    }

    function handleWalletPaymentConfirmation(){
        setConfirmTopUp(true);
    }

    function cancelConfirmTopUp(){
        setAmount(null);
        setConfirmTopUp(null);
    }

    function cancelPayment(){
        setConfirmTopUp(null);
    }

    function handleSetPaymentStatus(status){

        if(status===true){

            //clear all field
            setConfirmTopUp(null);
            setAmount(null);

        }else if(status===false){

            //clear all field
            setConfirmTopUp(null);
            setAmount(null);

        }

    }


return(

    <Container maxWidth={{ base: "container.sm", sm: "container.md", md: "container.xl" }} borderWidth={0} borderColor={"blue"} >


    { (amount===null) && <WalletPaymentForm handleWalletPaymentAmount={handleWalletPaymentAmount}/> }

    { (amount!==null) && <WalletPaymentConfirmation amount={amount} handleWalletPaymentConfirmation={handleWalletPaymentConfirmation} cancelConfirmTopUp={cancelConfirmTopUp}/> }

    { confirmTopUp==true && <WalletPaymentPaynow amount={amount} cancelPayment={cancelPayment}  handleSetPaymentStatus={handleSetPaymentStatus}/> }
    </Container>

);

}
export default WalletHome;