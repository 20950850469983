import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useAlertContext} from "../../context/AlertContext";
import {useUser} from "../../context/UserContext";
import {useState,useEffect} from "react";
import { 
    Spinner,
    Container,
    Center
 } from "@chakra-ui/react"


/**
 * call api to create order and get Stripe payment - client secret key
 * display Stripe paynow QR
 */
function MakePaymentWallet(props){

    const {authorizationToken,removeUser} = useUser();
    const {onOpen} = useAlertContext();

    const telecom=props.topUpData.telecom;
    const telecomValue=props.topUpData.telecomValue;
    const reloadValue=props.topUpData.reloadValue;

    const order_uuid=props.orderUuid;
    const phoneNumber= props.phoneNumber;

    const [orderId, setOrderId] = useState(null);

    const [handleAddSingleItemOrderWalletApiCall,isLoadingAddSingleItemOrderWallet, dataAddSingleItemOrderWallet, errorAddSingleItemOrderWallet] = useApiCalls();

    const TITLE_ERROR_ADD_SINGLE_ITEM_ORDER_WALLET="Error when create a new order !";
    const TITLE_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER_WALLET="Payment Successful !";
    const DESCRIPTION_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER_WALLET="Please view reload transactions for status of your reload.";

    const POST_ADD_SINGLE_ITEM_ORDER_WALLET_URL = BASE_URL+ "/api/v1/reload/create_single_item_order_wallet";


    useEffect(()=>{

        submitAddSingleItemOrderWallet();

    },[]);


    useEffect(()=>{

        if(dataAddSingleItemOrderWallet!==null){

            if(dataAddSingleItemOrderWallet.content.status==="successful"){
                setOrderId(dataAddSingleItemOrderWallet.content.order_id);
                props.handleSetPaymentStatus(true);
                onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER_WALLET, DESCRIPTION_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER_WALLET);
            }

        }

    },[dataAddSingleItemOrderWallet])


    useEffect(()=>{

        if(errorAddSingleItemOrderWallet!==null){
            props.handleSetPaymentStatus(false);

            if(errorAddSingleItemOrderWallet==="unauthorized_access"){
                onOpen(STATUS_ERROR,TITLE_ERROR_ADD_SINGLE_ITEM_ORDER_WALLET,"Login expired, please login again");
                removeUser();
            }else{
                onOpen(STATUS_ERROR,TITLE_ERROR_ADD_SINGLE_ITEM_ORDER_WALLET, errorAddSingleItemOrderWallet);
            }
        }

    },[errorAddSingleItemOrderWallet])


     //api calls add single item order
     const submitAddSingleItemOrderWallet = ()=>{

        //headers
        const config = {
            headers:{
                "Authorization": `Bearer ${authorizationToken}`,
                "Content-Type": "application/json"
            }
        };

         //body json params
         const apiJsonParams = {
            order_uuid: order_uuid,
            item_telecom: telecom.telecom,
            item_value_code: telecomValue.valueCode,
            item_name: telecomValue.valueName,
            item_cost_price: reloadValue.costPrice,
            item_sell_price: reloadValue.sellPrice,
            item_face_value: telecomValue.faceValue,
            phone_number: phoneNumber
        };

        const apiCalls ={
            apiConfig: config,
            url: POST_ADD_SINGLE_ITEM_ORDER_WALLET_URL,
            apiType: "post",
            apiJsonParams: apiJsonParams
        }

        handleAddSingleItemOrderWalletApiCall({apiCalls});//call api
    }
    //end add single item order


    return(

        <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}}  maxWidth={400}  borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg">
           <Center p={3}><Spinner size="xl"/> <p>Processing order...Please wait...</p></Center>
        </Container>

    );

}
export default MakePaymentWallet;