
import * as Yup from "yup";
import {useFormik} from "formik";
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormErrorMessage,
    Container,
    Text,
    Divider
  } from "@chakra-ui/react";
  import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
  import {useApiCalls} from "../../hooks/useApiCalls";
  import {useAlertContext} from "../../context/AlertContext";
  import {useEffect} from "react";


function ForgetPasswordVerifyOtpForm(props){

    const { onOpen } = useAlertContext();

    const [handleForgetPasswordVerifyOtpApiCall,isLoadingForgetPasswordVerifyOtp, dataForgetPasswordVerifyOtp, errorForgetPasswordVerifyOtp] = useApiCalls();

    const POST_FORGET_PASSWORD_VERIFY_OTP_URL= BASE_URL + "/api/v1/user/forget_password_verify_otp2";
    const TITLE_ERROR_FORGET_PASSWORD_VERIFY_OTP="Error with OTP verification";
    const TITLE_SUCCESSFUL_FORGET_PASSWORD_VERIFY_OTP="Verification successful";
    const DESCRIPTION_SUCCESSFUL_FORGET_PASSWORD_VERIFY_OTP="OTP verification is successful, please reset your password below.";

    const digitsOnly = (value) => /^\d+$/.test(value);

    const formik = useFormik({
        initialValues: {
          otp: "",
        },

        onSubmit: (values)=>{
            submitForgetPasswordVerifyOtpForm(values);
        },

    validationSchema: Yup.object({
        otp: Yup.string()
             .test("Digits only", "Must be 6 digit number", digitsOnly)
             .min(6,"Must be 6 digit.")
             .max(6, "Must be 6 digit")
             .required("Required")
      })
    });


    useEffect(()=>{

        if(dataForgetPasswordVerifyOtp!==null){
            if(dataForgetPasswordVerifyOtp.content.status==="successful"){
                onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_FORGET_PASSWORD_VERIFY_OTP, DESCRIPTION_SUCCESSFUL_FORGET_PASSWORD_VERIFY_OTP);
                props.handleForgetPasswordVerified(dataForgetPasswordVerifyOtp.content);
            }
        }

    },[dataForgetPasswordVerifyOtp])


    useEffect(()=>{

        if(errorForgetPasswordVerifyOtp!==null){

            onOpen(STATUS_ERROR, TITLE_ERROR_FORGET_PASSWORD_VERIFY_OTP, errorForgetPasswordVerifyOtp);

            if(errorForgetPasswordVerifyOtp==="verification code expired"){
                props.handleForgetPasswordVerificationExpiredCode();
            }

        }

    },[errorForgetPasswordVerifyOtp])


    function submitForgetPasswordVerifyOtpForm(values){

        //headers
        const config = {
        params:{
            verification_code: values.otp,
            phone_number: props.forgetPassword.phone_number,
            forget_password_id: props.forgetPassword.forget_password_id
            }
        };

        //body json params
        const apiJsonParams = {};

        const apiCalls ={
            apiConfig: config,
            url: POST_FORGET_PASSWORD_VERIFY_OTP_URL,
            apiType: "post",
            apiJsonParams: apiJsonParams
        }

        handleForgetPasswordVerifyOtpApiCall({apiCalls});
    }

    return (

        <Container  minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth={400} borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg">

        <Box pt={2} textAlign="center">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Verify OTP</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit}>

            <FormControl p={2} isInvalid={(formik.touched.otp && formik.errors.otp)} >
            <FormLabel>Verification Code (OTP)</FormLabel>
            <Input 
                type="text" 
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("otp")}
            />
            <FormErrorMessage>{formik.errors.otp}</FormErrorMessage>
            <Divider/>
            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }} color="blue">Check your SMS for the OTP.</Text>
            </FormControl>

            <Button width="full" mt={4} type="submit" isLoading={isLoadingForgetPasswordVerifyOtp}>
            Verify
            </Button>

        </form>

        </Box>
        </Container>

    );
}
export default ForgetPasswordVerifyOtpForm;