import ForgetPasswordForm from "./ForgetPasswordForm";
import ForgetPasswordVerifyOtpForm from "./ForgetPasswordVerifyOtpForm";
import ForgetPasswordResetPasswordForm from "./ForgetPasswordResetPasswordForm";
import {
    Flex,
    Container
  } from "@chakra-ui/react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";


function ForgetPassword(){

    const navigate = useNavigate();
    const [forgetPassword, setForgetPassword] = useState(null);
    const [forgetPasswordVerified,setForgetPasswordVerified] = useState(null);


function handleForgetPasswordForm(content){
    setForgetPassword(content);
}

function handleForgetPasswordVerificationExpiredCode(){
    setForgetPassword(null);
}


function handleForgetPasswordVerified(content){
    setForgetPasswordVerified(content);
}


//success
function handleForgetPasswordResetPassword(){
    setForgetPassword(null);
    setForgetPasswordVerified(null);
    navigate("/login");
}

    return(

        <Container maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }} borderWidth={0} borderColor={"blue"} align="top" minHeight="70vh" justifyContent="center">
        <Flex direction="column" justifyContent="center" >

        { (forgetPassword===null) && <ForgetPasswordForm handleForgetPasswordForm={handleForgetPasswordForm} /> }
        { (forgetPassword!==null && forgetPasswordVerified===null) && <ForgetPasswordVerifyOtpForm handleForgetPasswordVerified={handleForgetPasswordVerified} forgetPassword={forgetPassword} handleForgetPasswordVerificationExpiredCode={handleForgetPasswordVerificationExpiredCode}/>  }
        { (forgetPasswordVerified!==null) && <ForgetPasswordResetPasswordForm handleForgetPasswordResetPassword={handleForgetPasswordResetPassword} forgetPasswordVerified={forgetPasswordVerified}/>  }

        </Flex>
        </Container>
    );
}
export default ForgetPassword;
