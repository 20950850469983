import axios from "axios";
import {useState,useEffect} from "react";

export function useApiCalls(){

    const [isLoading, setIsLoading] = useState(null);
    const [data, setData] =useState(null);
    const [error, setError] =useState(null);


    function handleApiCalls({apiCalls}){

        //reset
        setIsLoading(null);
        setData(null);
        setError(null);
        
        if(apiCalls.apiType==="get"){
            doGetApiCalls({apiCalls});//do GET api calls
        }else if (apiCalls.apiType==="post"){
            doPostApiCalls({apiCalls});//do POST api calls
        }
    }


    function doGetApiCalls({apiCalls}){

        setIsLoading(true);

         //send GET api request
        const response = axios.get(
            apiCalls.url,
            apiCalls.apiConfig
        );
            
        response.then(function (res) {
            //setData(res.data);
            processData(res.data);
        })
        .catch(function (error) {      
            //setError(error);
            processError(error);
        })
        .finally(function () {
            setIsLoading(false);
        
        });

    }
    
    //process not http status 200
    function processError(error){

        if ((error.response?.data!==undefined) && (error.response?.data?.error!=="")){
            setFinalError(error.response?.data?.error);
        }else{
            setFinalError(error.message);
        }
    }

    function processData(data){

        if(data.content.status==="successful"){ 
            setData(data);
        }
        else if(data.error!=="" ){ 
            setFinalError(data.message);   //error message from api logic error e.g already exist email
        }
    }



    function setFinalError(errorMessage){
        setError(errorMessage);
    }

    function doPostApiCalls({apiCalls}){

        setIsLoading(true);

        //send POST api request
        const response = axios.post(
            apiCalls.url,
            apiCalls.apiJsonParams,
            apiCalls.apiConfig
            );

        response.then(function (res) {
            //setData(res.data);
            processData(res.data);
        })
        .catch(function (error) {      
            //setError(error);
            processError(error);
        })
        .finally(function () {
            setIsLoading(false);
        });

    }


    
        return [handleApiCalls,isLoading,data,error];    //destructure like const [isLoadingRoles, dataRoles, errorRoles] = useApiCalls({apiCalls});
        //return {isLoading,data,error};  //destructure like const {response: isLoadingRoles, data:dataRoles, error: errorRoles} = useApiCalls({apiCalls});

}