import {
    Card,
    CardBody,
    CardHeader,
    Image,
    Heading,
    Text,
    Alert,
    AlertIcon,
    Divider,
    ButtonGroup,
    Button,
    CardFooter,
    Spacer,
    Stack,
    SimpleGrid,
    Box,
    Flex
} from "@chakra-ui/react";
import { useState,useEffect } from "react";
import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";


function OrderItemCard(props){

    const date_locales="sg";
    const date_options=null; //{"hour12": false};

    //const [telecomValues, setTelecomValues] = useState(null);
    const telecomValues = props.telecomValues;
    const orderItem = props.orderItem;
    const telecoms = props.telecoms;

    /*useEffect(()=>{

        setTelecomValues(props.telecomValues);
    },[])*/
    const getImage = (telecomName,valueCode)=>{
        const telecomId = telecoms.find((telecom)=>telecom.telecom===telecomName).id;
        //console.log("telecomId: "+ JSON.stringify(telecomId));
        const telecomValue = telecomValues.find((value)=> {if (value.telecomId===telecomId && value.valueCode===valueCode)return value});
        //console.log("telecomValue: "+JSON.stringify(telecomValue));
        return telecomValue.image1;
    }

    return(
        <>
            <Card >
                <CardHeader >
                    <Flex>
                        <Box>
                        <Heading align='left' size={{ base: "xs", sm: "sm", md: "sm" }}>#{orderItem.order_id}</Heading>
                        </Box>
                        <Spacer />
                        <Box>
                        <Text align='right' fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{new Date(orderItem.created_at).toLocaleString({date_locales}, {date_options})}</Text>
                        </Box>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <SimpleGrid columns={{sm: 2, md: 3}} spacing='20px'>
                        <Box align="center">
                            <Image
                            boxSize={{ base: "80px", sm:"80px", md: "100px"}}
                            src={`${BASE_URL}/images/telecom_value_images/${getImage(orderItem.item_telecom, orderItem.item_value_code)}`}
                            borderRadius="lg"
                            boxShadow="lg"
                            />
                        </Box>
                        <Box>
                            <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>${orderItem.item_sell_price}</Text>
                            <Divider/>
                            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.item_telecom}</Text>
                            <Divider/>
                            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.item_name}</Text>
                            <Divider/>
                            <Text as={"b"} fontSize={{ base: "1rem", sm: "1rem", md: "1rem" }}>{orderItem.phone_number}</Text>
                            <Divider/>
                            {orderItem.status==="successful" && (<Button value={orderItem.order_id} onClick={props.handleGetReceipt} colorScheme='blue' variant='solid' size={{ base: "xs", sm: "sm", md: "sm" }} >
                                    Receipt
                                </Button>)}
                        </Box>
                        <Box>
                            <Alert size={{base: "xs"}} mb={2} status={orderItem.status==="successful" ? "success" : "error"} borderRadius={5} boxShadow="lg">
                                <AlertIcon />
                                <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.status}</Text>
                            </Alert>
                           
                            <Alert status='info' borderRadius={5} boxShadow="lg">
                                <AlertIcon />
                                <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.remarks}</Text>
                            </Alert>
                        </Box>
                    </SimpleGrid>
                </CardBody>
            </Card>
        </>
    );

}
export default OrderItemCard;