import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormErrorMessage,
    Container,
    Text,
    Divider
  } from "@chakra-ui/react";
import { BASE_URL, STATUS_SUCCESS, STATUS_ERROR } from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useAlertContext} from "../../context/AlertContext";
import {useEffect} from "react";

function ForgetPasswordForm(props){

    const {onOpen} = useAlertContext();

    const [handlePostForgetPasswordApiCall,isLoadingPostForgetPassword, dataPostForgetPassword, errorPostForgetPassword] = useApiCalls();

    const POST_FORGET_PASSWORD_URL = BASE_URL + "/api/v1/user/forget_password2";

    const TITLE_ERROR_POST_FORGET_PASSWORD="Error with forget password request !";
    const TITLE_SUCCESSFUL_POST_FORGET_PASSWORD="Request successful";
    const DESCRIPTION_SUCCESSFUL_POST_FORGET_PASSWORD="Please check your SMS for OTP to reset your password";

    const digitsOnly = (value) => /^\d+$/.test(value);
    const sgNumberOnly = (value) => /^(8|9)\d{7}$/.test(value);

    const formik = useFormik({
        initialValues: {
          phone_number: "",
        },

        onSubmit: (values)=>{
            submitForgetPasswordForm(values);
        },

    validationSchema: Yup.object({
        phone_number: Yup.string()
             .required("Required")
             .typeError("Must be 8 digit phone number")
             .test("Digits only", "Must be 8 digit phone number", digitsOnly)
             .test("Singapore mobile number only", "SG number starts with (8) or (9) and length of (8) digits", sgNumberOnly),
      })
    });


    useEffect(()=>{

        if(dataPostForgetPassword!==null){
            if(dataPostForgetPassword.content.status==="successful"){
                onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_POST_FORGET_PASSWORD, DESCRIPTION_SUCCESSFUL_POST_FORGET_PASSWORD);
                props.handleForgetPasswordForm(dataPostForgetPassword.content);
            }
        }

    },[dataPostForgetPassword])


    useEffect(()=>{

        if(errorPostForgetPassword!==null){
            onOpen(STATUS_ERROR, TITLE_ERROR_POST_FORGET_PASSWORD, errorPostForgetPassword);
        }

    },[errorPostForgetPassword])


    function submitForgetPasswordForm(values){

        //headers
        const config = {
            params:{
                phone_number: values.phone_number
                }
        };

        //body json params
        const apiJsonParams = {};

        const apiCalls ={
            apiConfig: config,
            url: POST_FORGET_PASSWORD_URL,
            apiType: "post",
            apiJsonParams: apiJsonParams
        }

      handlePostForgetPasswordApiCall({apiCalls});
    }


    return(

        <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth={400}  borderWidth={0} borderColor={"blue"}   borderRadius={8} boxShadow="lg">

        <Box pt={2} textAlign="center">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Forget Password</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit}>

            <FormControl p={2} isInvalid={(formik.touched.phone_number && formik.errors.phone_number)} >

            <FormLabel>Phone Number</FormLabel>
            <Input 
                type="number" 
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("phone_number")}
            />
            <FormErrorMessage>{formik.errors.phone_number}</FormErrorMessage>
            <Divider/>
            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }} color="blue">Enter your registered Phone number to reset your password.</Text>
            </FormControl>

            <Button width="full" mt={4} type="submit" isLoading={isLoadingPostForgetPassword}>
            Request Reset
            </Button>

        </form>

        </Box>
        </Container>

    );
}
export default ForgetPasswordForm;
