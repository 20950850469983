import {Navigate,useNavigate} from "react-router-dom";
import {useAlertContext} from "../context/AlertContext";
import {STATUS_ERROR} from "../utility/Constants";
import {useEffect} from "react";

function ProtectedRouteForLogonUser(props){

    const {onOpen} = useAlertContext();
    const navigate = useNavigate();

    const TITLE_ERROR_GET_LOGON_USER = "Unable to access !";

    useEffect(()=>{
        if(props.user===null){
            onOpen(STATUS_ERROR,TITLE_ERROR_GET_LOGON_USER,"Please login to access.");
            navigate("/login");
        }
    },[]);


    return (
        <>
            {
               props.user!==null ? props.children : <Navigate to="/" />
            }
        </>
    );
}
export default ProtectedRouteForLogonUser;