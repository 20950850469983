export const STATUS_SUCCESS = "success";
export const STATUS_ERROR = "error";

//export const BASE_URL = "http://139.180.135.224:8080";
//export const IMAGE_HOST = "http://139.180.135.224:8080";


export const BASE_URL = "https://api.ereloadsg.com:8443";
export const IMAGE_HOST = "https://api.ereloadsg.com:8443";

//export const BASE_URL = "http://localhost:8080";
//export const IMAGE_HOST = "http://localhost:8080";

//export const BASE_URL= "http://ereloadsg-backend-test-env.ap-southeast-1.elasticbeanstalk.com";

//export const IMAGE_HOST = "http://ereloadsg-backend-test-env.ap-southeast-1.elasticbeanstalk.com";