import * as Yup from "yup";
import { useFormik } from "formik";
import {
   Box,
   Heading,
   FormControl,
   FormLabel,
   Input,
   Button,
   FormErrorMessage,
   Container
 } from "@chakra-ui/react";

 import { useState,useEffect } from "react";


function SearchWalletTransactionForm(props){

    const todayDate=new Date().toISOString().split("T")[0]; //get date string as 2023-12-25

    const formik = useFormik({
        initialValues: {
          start_date: todayDate,
          end_date: todayDate,
        },

        onSubmit: (values)=>{
            props.updateSearchValues(values);
        },

    validationSchema: Yup.object({
        start_date: Yup.date()
            .typeError('Invalid date format')
            .required("Required"),
        end_date: Yup.date()
            .typeError('Invalid date format')
            .required("Required"),
      })
    });




return(

    <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth="400px"  borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg"> 

        <Box pt={2} textAlign="center">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Search Wallet Transaction</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit} autoComplete="on">

            <FormControl p={2} isInvalid={(formik.touched.start_date && formik.errors.start_date)} >
            <FormLabel>Start Date</FormLabel>
            <Input 
                type="date" 
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete={"on"}
                {...formik.getFieldProps("start_date")}
            />
            <FormErrorMessage>{formik.errors.start_date}</FormErrorMessage>
            </FormControl>

            <FormControl p={2} isInvalid={(formik.touched.end_date && formik.errors.end_date)}>
            <FormLabel>End Date</FormLabel>
            <Input 
                type="date" 
                placeholder="" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete={"on"}
                {...formik.getFieldProps("end_date")}
            />
            <FormErrorMessage>{formik.errors.end_date}</FormErrorMessage> 
            </FormControl>

            <Button width="full" mt={4} mb={4} type="submit" isLoading={props.isLoading}>
            Search
            </Button>

        </form>
        </Box>

    </Container>

);


}
export default SearchWalletTransactionForm;