import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {STRIPE_PK_KEY} from "../../utility/StripeSettings";
import {useAlertContext} from "../../context/AlertContext";
import {useUser} from "../../context/UserContext";
import {useState,useEffect} from "react";
import {loadStripe} from "@stripe/stripe-js";
import { 
    Spinner,
    Container,
    Center
 } from "@chakra-ui/react"


/**
 * call api to create order and get Stripe payment - client secret key
 * display Stripe paynow QR
 */
function MakePaymentPaynow(props){

    const {authorizationToken,removeUser} = useUser();
    const {onOpen} = useAlertContext();

    const [stripe, setStripe]  = useState(null);

    const telecom=props.topUpData.telecom;
    const telecomValue=props.topUpData.telecomValue;
    const reloadValue=props.topUpData.reloadValue;

    const order_uuid=props.orderUuid;
    const phoneNumber= props.phoneNumber;

    const [paymentIntentId, setPaymentIntentId] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [orderId, setOrderId] = useState(null);

    const [handleAddSingleItemOrderApiCall,isLoadingAddSingleItemOrder, dataAddSingleItemOrder, errorAddSingleItemOrder] = useApiCalls();

    const TITLE_ERROR_ADD_SINGLE_ITEM_ORDER="Error when create a new order !";
    const TITLE_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER="Payment Successful !";
    const DESCRIPTION_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER="Please view reload transactions for status of your reload.";

    const POST_ADD_SINGLE_ITEM_ORDER_URL = BASE_URL+ "/api/v1/reload/create_single_item_order_paynow";


    useEffect(()=>{

        if(clientSecret===null){
            //1) get stripe secret key
            submitAddSingleItemOrder();
        }

    },[]);


    useEffect(()=>{

        if(clientSecret!==null && stripe!==null){
            //2) call stripe paynow payment
            submitStripePaynow();
        }

    },[clientSecret,stripe]);


    useEffect(()=>{

        if(stripe===null){

            const stripe = loadStripe(STRIPE_PK_KEY);
            stripe.then((value)=>setStripe(value)); //resolve promise obj
        }

    },[stripe]);


    useEffect(()=>{

        if(dataAddSingleItemOrder!==null){

            if(dataAddSingleItemOrder.content.status==="successful"){
                //onOpen(STATUS_SUCCESS,TITLE_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER,DESCRIPTION_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER);
                setClientSecret(dataAddSingleItemOrder.content.client_secret);
                setOrderId(dataAddSingleItemOrder.content.order_id);
                setPaymentIntentId(dataAddSingleItemOrder.content.payment_intent_id);
            }

        }

    },[dataAddSingleItemOrder])


    useEffect(()=>{

        if(errorAddSingleItemOrder!==null){
            props.handleSetPaymentStatus(false);

            if(errorAddSingleItemOrder==="unauthorized_access"){
                onOpen(STATUS_ERROR,TITLE_ERROR_ADD_SINGLE_ITEM_ORDER,"Login expired, please login again");
                removeUser();
            }else{
                onOpen(STATUS_ERROR,TITLE_ERROR_ADD_SINGLE_ITEM_ORDER, errorAddSingleItemOrder);
            }
        }

    },[errorAddSingleItemOrder])


     //api calls add single item order
     const submitAddSingleItemOrder = ()=>{

        //headers
        const config = {
            headers:{
                "Authorization": `Bearer ${authorizationToken}`,
                "Content-Type": "application/json"
            }
        };

         //body json params
         const apiJsonParams = {
            order_uuid: order_uuid,
            item_telecom: telecom.telecom,
            item_value_code: telecomValue.valueCode,
            item_name: telecomValue.valueName,
            item_cost_price: reloadValue.costPrice,
            item_sell_price: reloadValue.sellPrice,
            item_face_value: telecomValue.faceValue,
            phone_number: phoneNumber
        };

        const apiCalls ={
            apiConfig: config,
            url: POST_ADD_SINGLE_ITEM_ORDER_URL,
            apiType: "post",
            apiJsonParams: apiJsonParams
        }

        handleAddSingleItemOrderApiCall({apiCalls});//call api
    }
    //end add single item order


    function submitStripePaynow(){

        stripe.confirmPayNowPayment(
            clientSecret
          )
          .then((res) => {
            if(res.paymentIntent.status === "succeeded") {

              // The user scanned the QR code
              props.handleSetPaymentStatus(true);
              //update backend payment success if window not closed
              //updateStripeOrderPayment();
              onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER, DESCRIPTION_SUCCESSFUL_ADD_SINGLE_ITEM_ORDER);

            } else {

              // The user closed the modal, cancelling payment
              //console.log("The user closed the modal, cancelling payment");
              props.handleSetPaymentStatus(false);

             // if(res.paymentIntent.status==="requires_action"){
            //    onOpen(STATUS_ERROR,"Payment Failed !","Payment not received !");
            //  }else{
                //onOpen(STATUS_ERROR,"Payment Failed !",res.paymentIntent.status);
                //go back to reload home?

            //  }

            }
        })
        .catch(function (error) {
            //console.log("error: "+error);
            onOpen(STATUS_ERROR,`An error has occured ! (${error})`,"Please check the reload history for confirmation, before buying again !");
        })

    }


    return(

        <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}}  maxWidth={400}  borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg">
            <Center p={3}><Spinner size="xl"/> <p>Processing order...Please wait...</p></Center>
        </Container>

    );

}
export default MakePaymentPaynow;