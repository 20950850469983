import {BASE_URL} from "../../utility/Constants";
import {
    Card,
    CardBody,
    Image,
    Heading,
    Text,
    Divider,
    ButtonGroup,
    Button,
    CardFooter,
    Center,
    Stack,
    Box
} from "@chakra-ui/react";


function ReloadValueCard(props){

const reloadValue = props.reloadValue;
const telecomValue = props.telecomValue;

function selectReloadValueId(e){
    props.selectReloadValueId(e.target.value);
}

return(

    <Card maxW="sm">
    <CardBody>
        <Center>
            <Image
             boxSize={{ base: "80px", sm:"140px", md: "180px"}}
            src={`${BASE_URL}/images/telecom_value_images/${telecomValue.image1}`}
            alt={telecomValue.valueName}
            borderRadius="lg"
            boxShadow="lg"
            />
        </Center>
        <Stack mt="6" spacing="3">
            <Text as={"b"} fontSize={{ base: "0.5rem", sm: "0.8rem", md: "1rem" }}>{telecomValue.valueName}</Text>
            <Text fontSize={{ base: "0.5rem", sm: "0.8rem", md: "1rem" }}>
                {telecomValue.valueDescShort}
            </Text>
        </Stack>
    </CardBody>

    <Divider />

    <CardFooter  justifyContent="center">
        <Text color="blue.600" fontSize={{ base: "0.8rem", sm: "1rem", md: "1.2rem" }} textAlign={"center"} mr={1}>
            ${reloadValue.sellPrice}
        </Text>
        <ButtonGroup spacing="2" display="flex"  mx="auto" justifyContent="center">
        <Button variant="solid" colorScheme="blue" value={reloadValue.id} onClick={selectReloadValueId} size={{ base: "xs", sm: "sm", md: "md" }}>
            Buy now
        </Button>
        </ButtonGroup>
    </CardFooter>

    </Card>

);

}
export default ReloadValueCard;