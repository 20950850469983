import {createContext, useContext, useState} from "react";

const AlertContext = createContext(undefined);

export const AlertProvider = ({ children }) => {

  const [state, setState] = useState({

   // isOpen: false,
    isOpen: false,
    // Type can be either "success" or "error"
    type: 'success',
     // title - like Error update user!
    title:  "",
    // Message to be displayed, can be any string like, invalid user phone num
    message: "",

  });

  return (
    <AlertContext.Provider
      value={
        {
        ...state,
        onOpen: (type, title, message) => setState({ isOpen: true, type, title, message }),
        onClose: () => setState({ isOpen: false, type: '', title: "", message: '' }),
      }
    }
    >
      {children}
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => useContext(AlertContext);