import {
    Container,
    Flex,
 } from "@chakra-ui/react"
import {useState,useEffect} from "react";
import {useReload} from "../../context/ReloadContext";
import PhoneNumberInput from "./PhoneNumberInput";
import ConfirmTopUp from "./ConfirmTopUp"
import MakePaymentPaynow from "./MakePaymentPaynow"
import MakePaymentWallet from "./MakePaymentWallet"
import UserReloadStatus from "./UserReloadStatus";
import { v4 as uuidv4 } from "uuid";
import SelectTelecom from "./SelectTelecom";
import SelectReloadValue from "./SelectReloadValue";
import Receipt from "./Receipt";


function ReloadHome(){

    //const {userId, isLoadingGetUserReloadValueList ,priceGroupId, telecoms, telecomValues, reloadValues} = useReload();
    const {telecoms, telecomValues, reloadValues} = useReload();

    const [selectedTelecomId, setSelectedTelecomId] =  useState(null);
    const [selectedReloadValueId, setSelectedReloadValueId] = useState(null);
    const [phoneNumberInput, setPhoneNumberInput] = useState("");
    const [nextConfirmTopUp, setNextConfirmTopUp] = useState(false);

    const [nextConfirmPayment, setNextConfirmPayment] = useState(null);
    const [orderUuid, setOrderUuid] = useState("");

    const [receiptOrderId,setReceiptOrderId] = useState(null);

   // const [paymentStatus, setPaymentStatus] = useState(false);

    useEffect(()=>{

        if(orderUuid===""){
            setOrderUuid(uuidv4());
        }

    },[orderUuid])


    function getTopUpData(reload_value_id){

        //console.log("reload_value_id: "+reload_value_id);
        const reloadValue = reloadValues.find(reloadValue => reloadValue.id == reload_value_id);
        //console.log(JSON.stringify(reloadValue));
        const telecomValue = telecomValues.find(telecomValue => telecomValue.id === reloadValue.telecomValueId);
        const telecom=  telecoms.find(telecom => telecom.id === telecomValue.telecomId);

        return {telecom:telecom, telecomValue:telecomValue, reloadValue:reloadValue}
    }


    function handlePhoneNumberInputAndNext(number){
        setPhoneNumberInput(number);
        setNextConfirmTopUp(true);
    }

    function cancelPhoneNumberInput(){
        setPhoneNumberInput("");
        setSelectedReloadValueId(null);
    }

    function cancelConfirmTopUp(){
        setNextConfirmTopUp(false);
    }


    function confirmPaymentWallet(){
      setNextConfirmPayment("wallet");
    }

    function confirmPaymentPaynow(){
        setNextConfirmPayment("paynow");
    }

    function cancelPayment(){
        setNextConfirmPayment(null);
    }

    function handleSetPaymentStatus(status){
        //setPaymentStatus(status);

        if(status===true){

            //clear all field
            setSelectedTelecomId(null);
            setSelectedReloadValueId(null);
            setPhoneNumberInput("");
            setNextConfirmTopUp(false);
            setNextConfirmPayment(null);
            setOrderUuid("");
           // setPaymentStatus(false);

        }else if(status===false){

            //setOrderUuid("");
            //setNextConfirmPayment(null);
             
            //clear all field
             setSelectedTelecomId(null);
             setSelectedReloadValueId(null);
             setPhoneNumberInput("");
             setNextConfirmTopUp(false);
             setNextConfirmPayment(null);
             setOrderUuid("");
            // setPaymentStatus(false);

        }

    }

    function selectTelecomId(id){
        setSelectedTelecomId(id);
    }

    function selectReloadValueId(id){
        setSelectedReloadValueId(id);
    }

    const handleGetReceipt = (value) => {setReceiptOrderId(value)} ;



    return(

           <Container maxWidth={{ base: "container.sm", sm: "container.md", md: "container.xl" }} borderWidth={0} borderColor={"blue"} >

            {(selectedTelecomId===null && receiptOrderId===null) &&  <SelectTelecom telecoms={telecoms} selectTelecomId={selectTelecomId} />}

            {(selectedTelecomId===null && receiptOrderId!==null) && <Receipt receiptOrderId={receiptOrderId} handleGetReceipt={handleGetReceipt} />}


            {(selectedTelecomId!==null && selectedReloadValueId===null) && <SelectReloadValue telecoms={telecoms} telecomValues={telecomValues} reloadValues={reloadValues} selectReloadValueId={selectReloadValueId} selectedTelecomId={selectedTelecomId} selectTelecomId={selectTelecomId}/>}

            {(selectedTelecomId!==null && selectedReloadValueId!==null && nextConfirmTopUp===false) && <PhoneNumberInput handlePhoneNumberInputAndNext={handlePhoneNumberInputAndNext} phoneNumberInput={phoneNumberInput} cancelPhoneNumberInput={cancelPhoneNumberInput}/> }

            {(selectedTelecomId!==null && selectedReloadValueId!==null && nextConfirmTopUp===true && nextConfirmPayment===null) && <ConfirmTopUp topUpData={getTopUpData(selectedReloadValueId)} phoneNumber={phoneNumberInput} cancelConfirmTopUp={cancelConfirmTopUp} confirmPaymentWallet={confirmPaymentWallet} confirmPaymentPaynow={confirmPaymentPaynow} />}
    {(selectedTelecomId!==null && selectedReloadValueId!==null && nextConfirmTopUp===true && nextConfirmPayment==="paynow")  && <MakePaymentPaynow topUpData={getTopUpData(selectedReloadValueId)} phoneNumber={phoneNumberInput} cancelPayment={cancelPayment} orderUuid={orderUuid} handleSetPaymentStatus={handleSetPaymentStatus}/> }

        
            {(selectedTelecomId!==null && selectedReloadValueId!==null && nextConfirmTopUp===true && nextConfirmPayment==="wallet")  && <MakePaymentWallet topUpData={getTopUpData(selectedReloadValueId)} phoneNumber={phoneNumberInput} cancelPayment={cancelPayment} orderUuid={orderUuid} handleSetPaymentStatus={handleSetPaymentStatus}/> }


            <Container mt={2} mb={2} maxWidth={{ base: "container.sm", sm: "container.md", md: "container.xl" }}  borderWidth={0} borderColor={"blue"}  borderRadius={5}   overflow="auto" >
            {(selectedTelecomId===null && receiptOrderId===null) && <UserReloadStatus handleGetReceipt={handleGetReceipt} telecomValues={telecomValues} telecoms={telecoms}/>}
            </Container>

        </Container>

    );

}
export default ReloadHome;