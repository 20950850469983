import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useApiCalls} from "../../hooks/useApiCalls";
import {useAlertContext} from "../../context/AlertContext";
import {useUser} from "../../context/UserContext";
import {useEffect} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormErrorMessage,
    Flex,
    Container
  } from "@chakra-ui/react";


function ChangePassword(props){

    const {authorizationToken,removeUser} = useUser();
    const { onOpen } = useAlertContext();
    const navigate = useNavigate();

    const TITLE_ERROR_CHANGE_PASSWORD="Error change password";
    const TITLE_SUCCESSFUL_CHANGE_PASSWORD="Change password successful";
    const DESCRIPTION_SUCCESSFUL_CHANGE_PASSWORD="Your password has been changed.";

    const [handleChangePasswordApiCall,isLoadingChangePassword, dataChangePassword, errorChangePassword] = useApiCalls();

    const POST_CHANGE_PASSWORD_URL = BASE_URL+ "/api/v1/user/change_password";

    const passwordFormat = (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$&=!*()<>:;]).{8,12}$/.test(value);
    const passwordMatch = () => formik.values.password1===formik.values.password2;


    const formik = useFormik({
        initialValues: {
          currentPassword: "",
          password1: "",
          password2: "",
        },

        onSubmit: (values)=>{
            submitChangePasswordForm(values);
        },

    validationSchema: Yup.object({
        currentPassword:Yup.string()
            .min(8,"Must be at least 6 characters")
            .max(12, "Max is 12 characters")
            .test("Password format", "At least (1) uppercase, (1) lowercase, (1) number and (1) special character[@#$&=!*()<>:;]", passwordFormat)
            .required("Required"),      
        password1:Yup.string()
             .min(8,"Must be at least 6 characters")
             .max(12, "Max is 12 characters")
             .test("Password format", "At least (1) uppercase, (1) lowercase, (1) number and (1) special character[@#$&=!*()<>:;]", passwordFormat)
             .required("Required"),      
        password2:Yup.string()
             .min(8,"Must be at least 8 characters")
             .max(12, "Max is 12 characters")
             .test("Password format", "At least (1) uppercase, (1) lowercase, (1) number and (1) special character[@#$&=!*()<>:;]", passwordFormat)
             .test("Password matching", "Passwords must be same", passwordMatch)
             .required("Required"),
      })
    });


    useEffect(()=>{

        if(dataChangePassword!==null){
            if(dataChangePassword.content.status==="successful"){
                onOpen(STATUS_SUCCESS, TITLE_SUCCESSFUL_CHANGE_PASSWORD, DESCRIPTION_SUCCESSFUL_CHANGE_PASSWORD);
                navigate("/");
            }
        }

      },[dataChangePassword])


    useEffect(()=>{

          if(errorChangePassword!==null){

            if(errorChangePassword==="unauthorized_access"){
                onOpen(STATUS_ERROR, TITLE_ERROR_CHANGE_PASSWORD,"Login expired, please login again");
                removeUser();
            }else{
                onOpen(STATUS_ERROR, TITLE_ERROR_CHANGE_PASSWORD, errorChangePassword);
            }

          }

      },[errorChangePassword])


    function submitChangePasswordForm(values){

        //headers
        const config = {
        headers:{
            Authorization: `Bearer ${authorizationToken}`
        },
        params:{
            current_password: values.currentPassword,
            password: values.password2
            }
      };

      //body json params
      const apiJsonParams = {};

      const apiCalls ={
          apiConfig: config,
          url: POST_CHANGE_PASSWORD_URL,
          apiType: "post",
          apiJsonParams: apiJsonParams
      }

      handleChangePasswordApiCall({apiCalls});
    }


    return (

        <Container maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }} borderWidth={0} borderColor={"blue"} align="top" minHeight="70vh" justifyContent="center">

        <Container mb={2} minWidth={{ base: "200px",sm: "350px", md: "400px"}}  maxWidth="400px" borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg">

        <Box pt={2} textAlign="center">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Change Password</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit}>

            <FormControl p={2} isInvalid={(formik.touched.currentPassword && formik.errors.currentPassword)} >
            <FormLabel>Current Password</FormLabel>
            <Input 
                type="password" 
                placeholder="*******" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("currentPassword")}
            />
            <FormErrorMessage>{formik.errors.currentPassword}</FormErrorMessage>
            </FormControl>

            <FormControl p={2} isInvalid={(formik.touched.password1 && formik.errors.password1)}>
            <FormLabel>Password</FormLabel>
            <Input 
                type="password" 
                placeholder="*******" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("password1")}
            />
            <FormErrorMessage>{formik.errors.password1}</FormErrorMessage> 
            </FormControl>

            <FormControl  p={2} isInvalid={(formik.touched.password2 && formik.errors.password2)}>
            <FormLabel>Password again</FormLabel>
            <Input 
                type="password" 
                placeholder="*******" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("password2")}
            />
            <FormErrorMessage>{formik.errors.password2}</FormErrorMessage>
            </FormControl>


            <Button width="full" mt={4} mb={5} type="submit" isLoading={isLoadingChangePassword}>
            Change
            </Button>

        </form>
    </Box>

    </Container>


    </Container>

    );


}
export default ChangePassword;