import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormErrorMessage,
    Container
  } from "@chakra-ui/react";


function PhoneNumberInput(props){

    const digitsOnly = (value) => /^\d+$/.test(value);
    const sgNumberOnly = (value) => /^(8|9)\d{7}$/.test(value);

    const formik = useFormik({
        initialValues: {
          phoneNum:props.phoneNumberInput,
        },
    
        onSubmit: (values)=>{
            props.handlePhoneNumberInputAndNext(values.phoneNum);
        },

    validationSchema: Yup.object({
        phoneNum: Yup.string()
             .required("Required")
             .typeError("Must be 8 digit phone number")
             .test("Digits only", "Must be 8 digit phone number", digitsOnly)
             .test("Singapore mobile number only", "SG number starts with (8) or (9) and length of (8) digits", sgNumberOnly),
      })
    });

    return(

        <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth={400}  borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg">

        <Box mt={2} textAlign="center" >
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Enter Phone Number to Top Up</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit}>

        <FormControl p={2} isInvalid={(formik.touched.phoneNum && formik.errors.phoneNum)}>
            <FormLabel fontSize={{ base: "0.8rem", sm: "1rem", md: "1.2rem" }}>Phone number</FormLabel>
            <Input 
                type="number" 
                placeholder=""
                size="xl"
                fontSize={{ base: "1.5rem", sm: "2rem", md: "2.5rem" }}
                letterSpacing="0.2em" 
                textAlign="center"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("phoneNum")}
            />
            <FormErrorMessage>{formik.errors.phoneNum}</FormErrorMessage>
            </FormControl>

        <Button width="full" mt={4} type="submit" variant="solid" colorScheme="blue">
        Next
        </Button>
        <Button width="full" mt={4} mb={4} onClick={props.cancelPhoneNumberInput} >
        Cancel
        </Button>

        </form>

        </Box>
        </Container>

    );
}
export default PhoneNumberInput;