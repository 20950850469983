import {useUser} from "../../context/UserContext";
import {useAlertContext} from "../../context/AlertContext";
import {useApiCalls} from "../../hooks/useApiCalls";
import {BASE_URL, STATUS_SUCCESS, STATUS_ERROR} from "../../utility/Constants";
import {useState,useEffect} from "react";
import SearchReloadForm from "./SearchReloadForm";
import {
    Flex,
    Box,
    Container,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Heading,
    Button
 } from "@chakra-ui/react";
import Receipt from "./Receipt";

function SearchReloadMain(){

    const {authorizationToken,removeUser} = useUser();
    const {onOpen} = useAlertContext();
    const [orderItemResult,setOrderItemResult] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const size=10;

    const [searchValues, setSearchValues] = useState(null);
    const [receiptOrderId,setReceiptOrderId] = useState(null);

    const updateSearchValues = (values)=>{setSearchValues(values)};


    const [handleGetOrderItemListApiCall,isLoadingGetOrderItemList, dataGetOrderItemList, errorGetOrderItemList] = useApiCalls();

    const TITLE_ERROR_GET_ORDER_ITEM_LIST="Error when get order item list !";
    const GET_ORDER_ITEM_LIST_URL = BASE_URL + "/api/v1/reload/get_order_item_list";

    const date_locales="sg";
    const date_options={"hour12": false};


    useEffect(()=>{

        if(searchValues!==null){
            getOrderItemListApiCall();
        }

    },[searchValues,currentPage]);


    useEffect(()=>{

        if(dataGetOrderItemList!==null){
            if(dataGetOrderItemList.content.status==="successful"){
                setOrderItemResult(dataGetOrderItemList.content.order_items);
                const current_page = dataGetOrderItemList.content.current_page;
                const total_pages = dataGetOrderItemList.content.total_pages;
                const total_items = dataGetOrderItemList.content.total_items;
                setCurrentPage(current_page);
                setTotalPages(total_pages);
                setTotalItems(total_items);
            }
        }

    },[dataGetOrderItemList])


    useEffect(()=>{

        if(errorGetOrderItemList!==null){

            if(errorGetOrderItemList==="unauthorized_access"){
                onOpen(STATUS_ERROR, TITLE_ERROR_GET_ORDER_ITEM_LIST,"Login expired, please login again");
                removeUser();
            }else{
            onOpen(STATUS_ERROR, TITLE_ERROR_GET_ORDER_ITEM_LIST, errorGetOrderItemList);
            }
        }

    },[errorGetOrderItemList])


     //api calls
     function getOrderItemListApiCall(){

        //headers
        const config = {
            headers:{
                "Authorization": `Bearer ${authorizationToken}`,
            }, 
            params:{
                start_date: searchValues.start_date,
                end_date: searchValues.end_date,
                page: currentPage,
                size: size
                }
            }

        //body json params
        const apiJsonParams = {};

        const apiCalls ={
            apiConfig: config,
            url: GET_ORDER_ITEM_LIST_URL,
            apiType: "get",
            apiJsonParams: apiJsonParams
        }

        handleGetOrderItemListApiCall({apiCalls});
    }

    const handleGetReceipt = (value)=>{
        setReceiptOrderId(value);
    }

    function handlePage(e){

        if(currentPage==e.target.value){
            //current page, no need do anything
        }
        else{
            setCurrentPage(e.target.value);
        }
    }
    
    function handlePreviousPage(e){
    
        if( currentPage==0){
        }
        else if( (totalPages-currentPage) >0){
            setCurrentPage(currentPage-1);
        }
    
    }
    
    function handleNextPage(e){
    
        if( (currentPage+1)===totalPages){
        }
        else if(currentPage < totalPages){
            setCurrentPage(currentPage+1);
        }
    }


    const pagination=(

        <>
            <Box pb={5}>
        
                <Box 
                as="button"
                height='24px'
                mr={2}    
                ml={2}
                border='1px'
                px='8px'
                borderRadius='50%'
                fontSize='14px'
                bg="#FFFFFF"
                borderColor='#ccd0d5'
                color='#4b4f56'
                _hover={{ bg: '#ebedf0' }}
                onClick={handlePreviousPage}
                >
                 {"<"}
                </Box>
        
        
            {
                [...Array(totalPages)].map( (e, i) => 
                
                
                
                <Box 
                key={i}
                as="button"
                height='24px'
                mr={2}    
                ml={2}
                border='1px'
                px='8px'
                borderRadius='50%'
                fontSize='14px'
                bg={currentPage==i ? '#ccd0d5' : "#FFFFFF" }
                borderColor='#ccd0d5'
                color='#4b4f56'
                _hover={{ bg: '#ebedf0' }}
                value={i} 
                onClick={handlePage}
                >
                { i+1 }
                </Box>
        
        
                )
            }
                <Box 
                as="button"
                height='24px'
                mr={2}    
                ml={2}
                border='1px'
                px='8px'
                borderRadius='50%'
                fontSize='14px'
                bg="#FFFFFF"
                borderColor='#ccd0d5'
                color='#4b4f56'
                _hover={{ bg: '#ebedf0' }}
                onClick={handleNextPage}
                >
                {">"}
                </Box>
        
            </Box>
        
        </>
        
        )


    const listOrderItems= (
        <>
            <Box textAlign="center">
            <Heading size={{ base: "sm", sm: "md", md: "lg" }} >Reload Transactions</Heading>
            </Box>

            <Box textAlign="left" ml={4}>
            <Text as={"b"} fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{`Total: ${totalItems}`}</Text>
            </Box>

            <Box my={4} textAlign="left" >

            <TableContainer >

                <Table size='sm' variant="striped" >

                    <Thead>
                    <Tr>
                        <Th>#Id</Th>
                        <Th>Telecom</Th>
                        <Th>Name</Th>
                        <Th>Phone Number</Th>
                        <Th>Price</Th>
                        <Th>Status</Th>
                        <Th>Remarks</Th>
                        <Th>Payment</Th>
                        <Th>Date</Th>
                    </Tr>
                    </Thead>

                    <Tbody>

                    {
                        orderItemResult?.map((orderItem)=>(
                            <Tr key={orderItem.id}>
                            <Td>
                                <Text mr={2} display="inline" fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.id}</Text>
                                {orderItem.status==="successful" && (<Button value={orderItem.order_id} onClick={()=>setReceiptOrderId(orderItem.order_id)} colorScheme='blue' variant='solid' size={{ base: "xs", sm: "sm", md: "sm" }} >
                                    Receipt
                                </Button>)}
                            </Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.item_telecom}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.item_name}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.phone_number}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.item_sell_price}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.status}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.remarks}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{orderItem.payment_method}</Text></Td>
                            <Td><Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }}>{new Date(orderItem.created_at).toLocaleString({date_locales}, {date_options})}</Text></Td>
                            </Tr>
                        ))
                    }
    
                    </Tbody>
    
                </Table>
                </TableContainer>
            </Box>
            </>
    )





    return(

    <Container maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }} borderWidth={0} borderColor={"blue"} align="top" minHeight="70vh">

        {/**searchForm */}
        {receiptOrderId===null && <SearchReloadForm searchValues={searchValues} updateSearchValues={updateSearchValues} isLoading={isLoadingGetOrderItemList} /> }


       {receiptOrderId===null &&  <Container mt={2} mb={2} maxWidth={{ base: "container.sm", sm: "container.sm", md: "container.xl" }}  borderWidth={0} borderColor={"blue"} borderRadius={8} boxShadow="lg">

            {/**search result table */}
            {(orderItemResult!==null) && listOrderItems}
            {(orderItemResult!==null) && pagination}

        </Container> }

        {receiptOrderId!==null && <Receipt receiptOrderId={receiptOrderId} handleGetReceipt={handleGetReceipt} />}

    </Container>

    );

}
export default SearchReloadMain;