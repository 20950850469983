import {useUser} from "../context/UserContext";
import {useState,useEffect} from "react";
import {useApiCalls} from "./useApiCalls";
import { BASE_URL, STATUS_SUCCESS, STATUS_ERROR } from '../utility/Constants';
import { useAlertContext } from "../context/AlertContext";


function useWallet(){

    const {user,authorizationToken,removeUser} = useUser();
    const { onOpen } = useAlertContext();

    const [wallet, setWallet] = useState(null);

    const [handleGetWalletApiCall,isLoadingGetWallet, dataGetWallet, errorGetWallet] = useApiCalls();
    const GET_WALLET_URL = BASE_URL + "/api/v1/user/get_wallet";
    
    const TITLE_ERROR_GET_WALLET="Error get wallet !";

    //call api
    useEffect(()=>{

        if(user!=null){

            getWalletApiCall();

        const interval = setInterval(() => {
            getWalletApiCall();
        }, 30000);

        return () => clearInterval(interval);

        }

    },[user]);


    //process success data
    useEffect(()=>{
        if(dataGetWallet!==null){
            if(dataGetWallet.content.status==="successful"){
                setWallet(dataGetWallet.content.user_wallet);
            }
        }
    },[dataGetWallet])


    //process error loading
    useEffect(()=>{

        if(errorGetWallet==="unauthorized_access"){
            onOpen(STATUS_ERROR,TITLE_ERROR_GET_WALLET,"Login expired, please login again");
            removeUser();
        }else{
            //onOpen(STATUS_ERROR,TITLE_ERROR_GET_WALLET,errorGetWallet);
        }

    },[errorGetWallet])

    function getWalletApiCall(){

        //headers
       const config = {
           headers:{
               Authorization: `Bearer ${authorizationToken}`
           }
         };

       const apiJsonParams={}

       const apiCalls ={
           apiConfig: config,
           url: GET_WALLET_URL,
           apiType: "get",
           apiJsonParams: apiJsonParams
       }

       //call api
       handleGetWalletApiCall({apiCalls});
   }


   return({wallet});


}
export default useWallet;