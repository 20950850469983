import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    Container,
    FormErrorMessage,
    Text
  } from "@chakra-ui/react";
  import { Link as ReactRouterLink } from "react-router-dom"
  import { Link as ChakraLink} from "@chakra-ui/react"


function LoginForm(props){

    const passwordFormat = (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$&=!*()<>:;]).{8,12}$/.test(value);
    const digitsOnly = (value) => /^\d+$/.test(value);
    const sgNumberOnly = (value) => /^(8|9)\d{7}$/.test(value);

    const formik = useFormik({
        initialValues: {
          userName: "",
          password: ""
        },
    
        onSubmit: (values)=>{
            props.submitLoginForm(values);
        },

    validationSchema: Yup.object({
        userName: Yup.string()
             .required("Required")
             .typeError("Must be 8 digit phone number")
             .test("Digits only", "Must be 8 digit phone number", digitsOnly)
             .test("Singapore mobile number only", "SG number starts with (8) or (9) and length of (8) digits", sgNumberOnly),
        password:Yup.string()
             .min(8,"Must be at least 6 characters")
             .max(12, "Max is 12 characters")
             .test("Password format", "At least (1) uppercase, (1) lowercase, (1) number and (1) special character[@#$&=!*()<>:;]", passwordFormat)
             .required("Required")
      })
    });

    return(

    
    <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth="400px"  borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg"> 
        
        <Box pt={2} textAlign="center">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Login</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit} autoComplete="on">

            <FormControl p={2} isInvalid={(formik.touched.userName && formik.errors.userName)} >
            <FormLabel>Phone Number</FormLabel>
            <Input 
                type="number" 
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={"userName"}
                autoComplete={"userName"}
                {...formik.getFieldProps("userName")}
            />
            <FormErrorMessage>{formik.errors.userName}</FormErrorMessage>
            </FormControl>

            <FormControl p={2} isInvalid={(formik.touched.password && formik.errors.password)}>
            <FormLabel>Password</FormLabel>
            <Input 
                type="password" 
                placeholder="*******" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={"password"}
                autoComplete={"current-password"}
                {...formik.getFieldProps("password")}
            />
            <FormErrorMessage>{formik.errors.password}</FormErrorMessage> 
            </FormControl>



            <Button width="full" mt={4} type="submit" isLoading={props.isLoading}>
            Login
            </Button>

        </form>
        </Box>

        <Box>
            <ChakraLink 
            as={ReactRouterLink} 
            to="/forget_password"
            _hover={{
                color: "blue",
            }} 
            >
            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }} color="blue">Forget your password? Reset here.</Text>
            </ChakraLink>
        </Box>

        <Box mt={3} pb={3}>
            <ChakraLink 
            as={ReactRouterLink} 
            to="/create_new_account_main"
            _hover={{
                color: "blue",
            }} 
            >
            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1rem" }} color="blue">No account? Create one here.</Text>
            </ChakraLink>
        </Box>

</Container>

    );
}
export default LoginForm;
