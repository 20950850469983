import PaynowLogo from "../../assets/paynowLogo.jpg";
import {IMAGE_HOST} from "../../utility/Constants";
import { Link } from "react-router-dom";
import {
      Stack,
      HStack,
      VStack,
      Text,
      Box,
      Heading,
      SimpleGrid,
      Center,
      Image,
      Container,
} from '@chakra-ui/react';
import {useUser} from "../../context/UserContext";
import {useReload} from "../../context/ReloadContext";

function Main(){

      const {user,authorizationToken} = useUser();
     // const { userData }  = useGetUser();
       const {userId, isLoadingGetUserReloadValueList ,priceGroupId, telecoms, telecomValues, reloadValues} = useReload();

       const displayNotLoginText=(

            <>
            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }} color='blue' display={"inline"}>
            <Link to="/create_new_account_main" >Register for an Account</Link>
            </Text>
            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }} display={"inline"}> and </Text>

            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }} color='blue' display={"inline"}>
            <Link to="/login">Login</Link> </Text>

            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }} display={"inline"}> before buying any reload.</Text>

            </>
       );

       const displayUserAlreadyLoginText = (
            <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "2rem" }} color='blue' display={"inline"}>
            <Link to="/reload_home" >Buy Now</Link>
            </Text>
       );



   return(

            <Container borderWidth={0}  maxWidth={{ base: "container.sm", sm: "container.md", md: "container.xl" }} >

            <Box textAlign="center" borderWidth={1} borderRadius={8} boxShadow="lg">
            <Heading size={{ base: "sm", sm: "md", md: "lg" }} >We provide Reloads to the following Telecoms in Singapore</Heading>
            </Box>

            <Box m={5} textAlign={"center"}>

            {user!==null ? displayUserAlreadyLoginText : displayNotLoginText}

            </Box>

            <SimpleGrid minChildWidth={{ base: "50px",sm: "100px", md: "150px"}} spacing="20px" m={5} >

                  {
                        telecoms?.map((telecom,i)=>

                        <Box justifyContent={"center"} key={i} borderWidth={0} borderColor={"black"} minWidth={50}>
                        <Center>
                        <Image
                        boxSize={{ base: "50px",sm: "100px", md: "150px"}}
                        src={`${IMAGE_HOST}/images/telecom_images/${telecom.image1}`}
                        alt={telecom.telecom}
                        borderRadius="lg"
                        boxShadow="lg"
                        />
                        </Center>
                        </Box>
                        )
                  }

            </SimpleGrid>

            <Box><Text m={5} fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }}  > **Notes: Only <i>prepaid top ups</i> are available for Singtel, Starhub, M1.</Text></Box>

            <HStack justifyContent={"right"} >
                  <Image
                  src={PaynowLogo} 
                  alt="paynow logo"
                  borderRadius="lg"
                  w={{ base: "96px",sm: "143px", md: "238px"}}
                  h={{ base: "60px", sm: "90px", md: "149px"}}
                  />
                  <Text fontSize={{ base: "0.7rem", sm: "1rem", md: "1.2rem" }}>Payment can only be made by PayNow.</Text>
            </HStack>

            </Container>

         );

}

export default Main;
