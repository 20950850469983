import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormErrorMessage,
    Container
  } from "@chakra-ui/react";


function WalletPaymentForm(props){

    const doubleValueOnly = (value) => /^[0-9]+(\.[0-9]{1,2})?$/.test(value);
   
    const formik = useFormik({
        initialValues: {
          amount:0
        },
    
        onSubmit: (values)=>{
            props.handleWalletPaymentAmount(values.amount);
        },

    validationSchema: Yup.object({
        amount: Yup.number()
        .test("Max 2 decimal place amount", "Max 2 decimal place value", doubleValueOnly)
        .min(1,"At least 1")
        .required("Required"),
      })
    });

    return(

        <Container minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth={400}  borderWidth={0} borderColor={"blue"}  borderRadius={8} boxShadow="lg">

        <Box mt={2} textAlign="center" >
        <Heading size={{ base: "sm", sm: "md", md: "lg" }}>Enter Wallet amount to Recharge</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <form onSubmit={formik.handleSubmit}>

        <FormControl p={2} isInvalid={(formik.touched.amount && formik.errors.amount)}>
            <FormLabel fontSize={{ base: "0.8rem", sm: "1rem", md: "1.2rem" }}>Amount (S$)</FormLabel>
            <Input 
                type="text" 
                placeholder=""
                size="xl"
                fontSize={{ base: "1.5rem", sm: "2rem", md: "2.5rem" }}
                letterSpacing="0.2em" 
                textAlign="center"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...formik.getFieldProps("amount")}
            />
            <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
            </FormControl>

        <Button width="full" mt={4} mb={4} type="submit" variant="solid" colorScheme="blue">
        Next
        </Button>

        </form>

        </Box>
        </Container>

    );
}
export default WalletPaymentForm;