import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogFooter,
    Button,
    Text,
    Box,
    VStack
  } from "@chakra-ui/react";
  import { WarningIcon, CheckCircleIcon } from "@chakra-ui/icons";
  import { useAlertContext } from "../context/AlertContext";
  import { useRef } from "react";

  /**
   * This is a global component that uses context to display a global alert message.
   */
  function Alert() {

    const { isOpen, type, title ,message, onClose } = useAlertContext();
    const cancelRef = useRef();
    const isSuccess = type === "success" // backgroundColor={isSuccess ? "#81C784" : "#FF8A65"}

    return (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay borderRadius="lg">
          <AlertDialogContent py={4}  borderRadius="lg">
            <AlertDialogHeader fontSize="lg" fontWeight="bold" borderRadius="lg">

              <VStack justifyContent="center">
                <Box>
                    {isSuccess ? <CheckCircleIcon boxSize={12} color="green.500"/> : <WarningIcon boxSize={12} color="red.500"/> }
                </Box>
                <Box >{title}</Box>
              </VStack>

            </AlertDialogHeader>

            <AlertDialogBody textAlign="center">
              <Text fontSize="lg">{message}</Text>
            </AlertDialogBody>

            <AlertDialogFooter >

            <Button ref={cancelRef} colorScheme="blue" onClick={onClose} ml={3}>
              Ok
            </Button>

            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );

  }
  export default Alert;