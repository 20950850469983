import "./App.css";
import Footer from "./sections/footer/Footer";
import Header from "./sections/header/Header.js";
import Main from "./sections/main/Main.js";
import ContactUs from "./sections/support/ContactUs.js";
import CreateNewAccountMain from "./sections/user/CreateNewAccountMain.js";
import Login from "./sections/user/Login.js";
import Logout from "./sections/user/Logout.js";
import UserProfile from "./sections/user/UserProfile";
import ChangePassword from "./sections/user/ChangePassword.js";
import ForgetPassword from "./sections/user/ForgetPassword";
import ProtectedRouteForLogonUser from "./components/ProtectedRouteForLogonUser";
import ProtectedRouteNotForLogonUser from "./components/ProtectedRouteNotForLogonUser";
import {Routes, Route} from "react-router-dom";
import {useUser} from "./context/UserContext";
import ReloadHome from "./sections/reload/ReloadHome";
import Alert from "./components/Alert";
import {Container} from "@chakra-ui/react";
import SearchWalletTransactionMain from "./sections/user/SearchWalletTransactionMain.js";
import SearchReloadMain from "./sections/reload/SearchReloadMain.js";
import WalletHome from "./sections/user/WalletHome.js";
import Privacy from "./sections/etc/Privacy.js";
import TermAndCondition from "./sections/etc/TermAndCondition.js";

function App() {

  const {user,completeLoading} = useUser();

//use these brakpoints
/*const breakpoints = {
  base: "0px",
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

//Extend the theme
const theme = extendTheme({ breakpoints });*/


  const displayRoutes = (

    <>
    <Header/>

    <Container mt={2} borderWidth={0} borderColor={"blue"} minWidth="95vw" minHeight="70vh" position="relative"  >
    <Routes>

{/**protected routes ONLY FOR LOGON USER **/}

      <Route
      path="/profile"
      element={
          <ProtectedRouteForLogonUser user={user} >
          <UserProfile/>
          </ProtectedRouteForLogonUser>
      } />

      <Route
      path="/change_password"
      element={
          <ProtectedRouteForLogonUser user={user} >
          <ChangePassword/>
          </ProtectedRouteForLogonUser>
      } />

      <Route
      path="/logout"
      element={
          <ProtectedRouteForLogonUser user={user} >
          <Logout/>
          </ProtectedRouteForLogonUser>
        } />

      <Route
      path="/reload_home"
      element={
          <ProtectedRouteForLogonUser user={user} >
          <ReloadHome/>
          </ProtectedRouteForLogonUser>
        } />

      <Route
      path="/search_wallet_transaction"
      element={
          <ProtectedRouteForLogonUser user={user} >
          <SearchWalletTransactionMain/>
          </ProtectedRouteForLogonUser>
        } />

      <Route
      path="/search_reload"
      element={
          <ProtectedRouteForLogonUser user={user} >
          <SearchReloadMain/>
          </ProtectedRouteForLogonUser>
        } />

      <Route
      path="/wallet_home"
      element={
          <ProtectedRouteForLogonUser user={user} >
          <WalletHome/>
          </ProtectedRouteForLogonUser>
        } />


{/**end protected routes ONLY FOR LOGON USER **/}


{/**public routes **/}

      <Route path="/" element={<Main/>} ></Route>
      <Route path="*" element={<Main/>} ></Route>
      <Route path="/contact_us" element={<ContactUs/>} ></Route>
      <Route path="/privacy" element={<Privacy/>} ></Route>
      <Route path="/terms_and_conditions" element={<TermAndCondition/>} ></Route>

{/**end public routes **/}


{/**protected routes NOT FOR LOGON USER **/}

      <Route
      path="/forget_password"
      element={
          <ProtectedRouteNotForLogonUser user={user} >
          <ForgetPassword/>
          </ProtectedRouteNotForLogonUser>
      } />


    <Route
    path="/login"
    element={
        <ProtectedRouteNotForLogonUser user={user} >
        <Login/>
        </ProtectedRouteNotForLogonUser>
    } />


    <Route
    path="/create_new_account_main"
    element={
        <ProtectedRouteNotForLogonUser user={user} >
        <CreateNewAccountMain/>
        </ProtectedRouteNotForLogonUser>
      } />




  {/**end protected routes NOT FOR LOGON USER **/}

      </Routes>

      </Container>

      <Footer/>
      <Alert />
      </>
  )


  return (

    <>

    {completeLoading===false && <p>...loading</p>}

    {completeLoading===true &&  displayRoutes}

    </>
  );

}
export default App;
