import {IMAGE_HOST} from "../../utility/Constants";
import {
    Text,
    Heading,
    Box,
    Button,
    Center,
    Image,
    Container
} from "@chakra-ui/react";
import useWallet from "../../hooks/useWallet";

function ConfirmTopUp(props){

    const {wallet} = useWallet();
    const telecom=props.topUpData.telecom;
    const telecomValue=props.topUpData.telecomValue;
    const reloadValue=props.topUpData.reloadValue;
    const phoneNumber= props.phoneNumber;

    const displayPaymentByPaynow = (

        <Button width="full" mt={4} type="submit" variant="solid" colorScheme="blue" onClick={props.confirmPaymentPaynow}>
        Payment by PayNow
        </Button>

    );

    const displayPaymentByWallet = (

        <Button width="full" mt={4} type="submit" variant="solid" colorScheme="blue" onClick={props.confirmPaymentWallet}>
        Payment by Wallet Balance
        </Button>

    );

    return(

        <Container mb={2} minWidth={{ base: "200px",sm: "350px", md: "400px"}} maxWidth={400} borderWidth={0} borderColor={"blue"} borderRadius={8} boxShadow="lg">

        <Box textAlign="center" >
        <Heading size="xl">Confirm top up ?</Heading>
        </Box>

        <Box my={4} textAlign="left">

        <Box justifyContent={"center"} borderWidth={0} borderColor={"blue"} minWidth={100} >
        <Heading mt={2} mb={5} size="md" color={"blue"} textAlign={"center"}>{telecom.telecom} - {telecomValue.valueName}</Heading>

            <Center>
            <Image
            boxSize={{ base: "150px",sm: "200px"}}
            src={`${IMAGE_HOST}/images/telecom_value_images/${telecomValue.image1}`}
            alt={telecomValue.valueName}
            borderRadius="lg"
            />
            </Center>

        </Box>

        <Box justifyContent={"center"} borderWidth={0} borderColor={"black"} minWidth={100} >
        <Heading mt={{ base: "5px",sm: "10px"}} size="md" textAlign={"center"}>Payment Amount</Heading>

            <Center>
            <Text fontSize="3xl" color="blue">${reloadValue.sellPrice}</Text>
            </Center>

        </Box>

        <Box justifyContent={"center"} borderWidth={0} borderColor={"black"} minWidth={100} >

            <Heading mt={{ base: "5px",sm: "10px"}} size="md" textAlign={"center"}>For Phone Number</Heading>

            <Center>
            <Text fontSize="6xl" color="blue">{phoneNumber}</Text>
            </Center>

        </Box>


        { (wallet?.balance >= reloadValue.sellPrice) && displayPaymentByWallet }
        { (wallet?.balance < reloadValue.sellPrice) && displayPaymentByPaynow }
        
        <Button width="full" mt={4} mb={4} onClick={props.cancelConfirmTopUp} isLoading={wallet===null}>
        Cancel
        </Button>



        </Box>

        </Container>

    );

}
export default ConfirmTopUp;