import {
    Button,
    Text
} from "@chakra-ui/react";
import {Link} from "react-router-dom";


function ButtonLink(props){

/**
 * e.g <ButtonLink background="#00008B" color="#FFFFFF" hover_background="#FFFFFF" hover_color="blue" to="/reload_home" link_text="Buy Now" onClick={onClose}/>
*/

return(

    <Link to={props.to}>
        <Button
        onClick={props.onClick}
        background= {props.background}
        color={props.color}
        _hover={{
            background: `${props.hover_background}`,
            color: `${props.hover_color}`
        }}>

        <Text fontSize={{ base: "1rem", md: "1rem" }}>
        {props.link_text}
        </Text>

        </Button>
    </Link>

    );
}
export default ButtonLink;