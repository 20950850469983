import {IMAGE_HOST} from "../../utility/Constants";
import {
    SimpleGrid,
    Center,
    Container,
    Image,
    Box,
    Heading,
    VStack,
    HStack
 } from "@chakra-ui/react";


function SelectTelecom(props){


    return(

        
<>

        <Box textAlign="center" borderWidth={1} borderRadius={8} boxShadow="lg">
        <Heading size={{ base: "sm", sm: "md", md: "lg" }} >Select Telecom to Reload</Heading>
        </Box>

        <SimpleGrid minChildWidth={{ base: "80px",sm: "100px", md: "150px"}} spacing="20px" m={5} >

            {
                props.telecoms?.map((telecom,i)=>

                <Box justifyContent={"center"} key={i} borderWidth={0} minWidth={50} onClick={()=>props.selectTelecomId(telecom.id)}>
                 <Center>
                 <Image
                boxSize={{ base: "80px",sm: "100px", md: "150px"}}
                src={`${IMAGE_HOST}/images/telecom_images/${telecom.image1}`}
                alt={telecom.telecom}
                borderRadius="lg"
                boxShadow="lg"
                cursor="pointer"
                />
                </Center>
                </Box>

                )
            }

        </SimpleGrid>

        </>
        
    );

}
export default SelectTelecom;